import builder, { Type } from '@comall-backend-builder/core';
import { ArrayNoValidationFormat, formats } from '@comall-backend-builder/types';
import {
    IdArraySelectMode,
    UploadArrayMode,
    SelectTimeRangesMode,
    MacauTimeRangesMode,
    RolePrivilegeMode,
    StoreImageMode,
    BrandRuleModal,
    BrandImageMode,
    InputArrayMode,
    ArrayTreeOptionNodeMode,
    AsyncArrayTreeNodeIdsTreeMode,
    AsyncArrayTreeNodeIdsTreeFormat,
    UploadImageVideoMode,
    ArrayAllOptionsAutoCompleteMode,
    ArrayEmailInputMode,
    SpecialAttributeMode,
    PriceSectionMode,
    ProductSuiteItemsMode,
    ProductSuiteItemsFormat,
    ProductGroupItemsFormat,
    ProductGroupItemsMode,
    ProductStyleItemsFormat,
    ProductStyleItemsMode,
    VoucherManagementItemsMode,
    VoucherManagementItemsFormat,
    SalesMultiPromotionItemsFormat,
    SalesMultiPromotionItemsMode,
    DynamicFieldArrayMode,
    ArrayThirdPartyOrderGoodsMode,
    SelectDateMode,
    SelectDateFormat,
    TreeWithParams,
} from './modes';
import {
    SelectTimeRangeFormat,
    MacauTimeRangeFormat,
    StoreImageFormat,
    RolePrivilegeFormat,
    BrandImageFormat,
    UrlImageFormat,
    InputArrayFormat,
    ArrayTreeOptionNodeFormat,
    UploadImageVideoFormat,
} from './formats';
import { ArrayAsyncTreeSelectFormat, ArrayAsyncTreeSelectMode } from './array-async-tree-select';
import { DeliveryFreightRulesMode } from './modes/delivery-freight-rules';
import { ArrayOnlineStoreGroupMode } from './array-online-store-group';
import {
    PromotionExchangeGroupProductFormat,
    PromotionExchangeGroupProductMode,
} from './promotion-group-product';
import {
    ArrayMerchantBannerListEditMode,
    ArrayMerchantBannerListEditFormat,
} from './array-merchant-banner-list-edit';
import { ArrayCouponGroupMode } from './array-coupon-group';
builder.registerType(
    'array.treeSelect.async',
    new Type(new ArrayAsyncTreeSelectFormat(), new ArrayAsyncTreeSelectMode())
);
builder.registerType(
    'array.idArray.select',
    new Type(new formats.ArrayFormat(), new IdArraySelectMode())
);
builder.registerType(
    'array.uploadArray',
    new Type(new formats.ArrayFormat(), new UploadArrayMode())
);

builder.registerType(
    'array.selectTimeRange',
    new Type(new SelectTimeRangeFormat(), new SelectTimeRangesMode())
);
builder.registerType(
    'array.macauTimeRange',
    new Type(new MacauTimeRangeFormat(), new MacauTimeRangesMode())
);

builder.registerType('array.storeImage', new Type(new StoreImageFormat(), new StoreImageMode()));

builder.registerType(
    'array.rolePrivilege',
    new Type(new RolePrivilegeFormat(), new RolePrivilegeMode())
);

builder.registerType('array.brandRule', new Type(new formats.ArrayFormat(), new BrandRuleModal()));

builder.registerType('array.brandImage', new Type(new BrandImageFormat(), new BrandImageMode()));
builder.registerType('array.urlImage', new Type(new UrlImageFormat(), new BrandImageMode()));
builder.registerType(
    'array.all.params',
    new Type(new formats.ArrayNoValidationFormat(), new ArrayAllOptionsAutoCompleteMode())
);
builder.registerType('array.inputArray', new Type(new InputArrayFormat(), new InputArrayMode()));
builder.registerType(
    'string.treeOptionNode.cascader',
    new Type(new ArrayTreeOptionNodeFormat(), new ArrayTreeOptionNodeMode())
);
builder.registerType(
    'array.treeNodeIds.asyncTree',
    new Type(new AsyncArrayTreeNodeIdsTreeFormat(), new AsyncArrayTreeNodeIdsTreeMode())
);

builder.registerType(
    'array.image.video',
    new Type(new UploadImageVideoFormat(), new UploadImageVideoMode())
);

builder.registerType(
    'array.email.input',
    new Type(new formats.ArrayFormat(), new ArrayEmailInputMode())
);

builder.registerType(
    'array.specialAttribute',
    new Type(new RolePrivilegeFormat(), new SpecialAttributeMode())
);

builder.registerType(
    'array.priceSection',
    new Type(new RolePrivilegeFormat(), new PriceSectionMode())
);
builder.registerType(
    'array.productSuiteItems',
    new Type(new ProductSuiteItemsFormat(), new ProductSuiteItemsMode())
);
builder.registerType(
    'array.productGroupItems',
    new Type(new ProductGroupItemsFormat(), new ProductGroupItemsMode())
);
builder.registerType(
    'array.productStyleItems',
    new Type(new ProductStyleItemsFormat(), new ProductStyleItemsMode())
);
builder.registerType(
    'array.delivery.rules',
    new Type(new ArrayNoValidationFormat(), new DeliveryFreightRulesMode())
);
builder.registerType(
    'array.onlineStoreGroup',
    new Type(new ArrayNoValidationFormat(), new ArrayOnlineStoreGroupMode())
);
builder.registerType(
    'array.voucherManagementItems',
    new Type(new VoucherManagementItemsFormat(), new VoucherManagementItemsMode())
);
builder.registerType(
    'array.salesMultiPromotion',
    new Type(new SalesMultiPromotionItemsFormat(), new SalesMultiPromotionItemsMode())
);
builder.registerType(
    'array.dynamic',
    new Type(new ArrayNoValidationFormat(), new DynamicFieldArrayMode())
);
builder.registerType(
    'array.exchangeProduct',
    new Type(new PromotionExchangeGroupProductFormat(), new PromotionExchangeGroupProductMode())
);
builder.registerType(
    'array.thirdPartyOrderGoods',
    new Type(new ArrayNoValidationFormat(), new ArrayThirdPartyOrderGoodsMode())
);
builder.registerType(
    'array.merchantBannerListEdit',
    new Type(new ArrayMerchantBannerListEditFormat(), new ArrayMerchantBannerListEditMode())
);
builder.registerType('array.selectDate', new Type(new SelectDateFormat(), new SelectDateMode()));
builder.registerType(
    'array.couponGroup',
    new Type(new ArrayNoValidationFormat(), new ArrayCouponGroupMode())
);

builder.registerType(
    'array.treeNodeIds.withParam',
    new Type(new ArrayNoValidationFormat(), new TreeWithParams())
);
