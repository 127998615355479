/*
 * @Author: zhulu
 * @Date: 2023-02-03 14:05:16
 * @Description: 营促销管理/单品多品促销管理-加价换购-商品组件
 */
/*eslint-disable react-hooks/exhaustive-deps */
import { services, TypesManager } from '@comall-backend-builder/core';
import { uuid } from '@comall-backend-builder/core/lib/services';
import { Button, Form } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { findIndex, cloneDeep, isEmpty, get } from 'lodash';
import SelectAutoComplete from '@/components/select-auto-complete';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
    SELECTED_DEFAULT_OPTIONS,
    SELECTED_DEFAULT_VALUES,
    STYLE_COMMODITY_TERMS_OPTIONS,
    STYLE_COMMODITY_TERMS_VALUES,
} from '@/constants';
import { buildPropertiesByLanguage } from '@/services/language-utils';
import { Entity } from '@comall-backend-builder/core/lib/parser';

interface Props<T> {
    /**
     * 输入组件的 value
     */
    value: {
        __uuid: string;
        product: AnyObject;
        selectedDefault: SELECTED_DEFAULT_VALUES;
        [key: string]: any;
    }[];
    /**
     * 输入组件的 name
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: T[], name: string) => void;
    /** 最大项 */
    maxLimit: number;
    formItem?: FormItemProps;
    entity: Entity;
}
export const PromotionExchangeGroupProductItem = (props: Props<any>) => {
    const { value = [], onChange, name, maxLimit = 10, entity } = props;

    useEffect(() => {
        if (value.length === 0) {
            onChange([{ __uuid: uuid() }], name);
        }
    }, []);

    /** 为value包装id */
    const formatValue = useMemo(() => {
        let _value = [];
        _value = value.map((item) => {
            if (!item) {
                return { __uuid: uuid() };
            } else {
                return { ...item, __uuid: item.__uuid || uuid() };
            }
        });
        return _value;
    }, [value]);
    /** 删除项 */
    const onRemove = useCallback(
        (item: any) => {
            if (formatValue.length <= 1) {
                return;
            }

            const index = findIndex(formatValue, { __uuid: item.__uuid });
            let nValue = cloneDeep(value);
            nValue.splice(index, 1);
            onChange(nValue, name);
        },
        [value]
    );
    /** 新增项 */
    const onAdd = useCallback(() => {
        if (formatValue.length === maxLimit) {
            return;
        }
        let nValue = cloneDeep(formatValue);
        onChange([...nValue, { __uuid: uuid() }], name);
    }, [formatValue]);

    const onItemChange = useCallback(
        (_value) => {
            const index = findIndex(formatValue, { __uuid: _value.__uuid });
            let nValue = cloneDeep(formatValue);
            nValue[index] = _value;
            onChange(nValue, name);
        },
        [formatValue]
    );

    const getDefaultValue = useMemo(() => {
        const index = findIndex(formatValue, { selectedDefault: SELECTED_DEFAULT_VALUES.YES });
        if (index > -1) return formatValue[index];
        else return formatValue[0];
    }, [formatValue]);

    const renderItem = (item: any) => {
        const complementProps = {
            name: `${name}${item.__uuid}`,
            key: item.__uuid,
        };
        const fieldsConfig = {
            exchangePurchasePrice: {
                type: 'number',
                required: true,
                min: 0,
                max: 99999999.99,
                step: 0.01,
                displayName: services.language.getText(
                    'salesPromotion.conditionsReward.exchangePurchasePrice'
                ),
            },
            swapSelect: {
                type: 'string.options.radio',
                options: SELECTED_DEFAULT_OPTIONS,
                displayName: services.language.getText(
                    'salesPromotion.conditionsReward.selectedDefault'
                ),
                initialValue:
                    getDefaultValue.__uuid === item.__uuid
                        ? SELECTED_DEFAULT_VALUES.YES
                        : SELECTED_DEFAULT_VALUES.NO,
            },
            styleCommodityTerms: {
                type: 'string.options.radio',
                options: STYLE_COMMODITY_TERMS_OPTIONS,
                displayName: services.language.getText(
                    'salesPromotion.conditionsReward.styleCommodityTerms'
                ),
                initialValue: STYLE_COMMODITY_TERMS_VALUES.NO,
            },
            ...buildPropertiesByLanguage('skuClause', {
                type: 'string.richText.video',
                displayName: services.language.getText(
                    'salesPromotion.conditionsReward.clauseGoods'
                ),
                video: {
                    uploadUrl:
                        ENV.API_ROOT + '/dc-file/video/oss/video_locations/11/videos?rename=true',
                    fileName: 'fileName',
                    accept: 'video/mp4',
                    maxSize: 20 * 1024,
                    // maxDuration: 60,
                },
                img: {
                    uploadUrl: ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    fileName: 'fileName',
                    accept: 'image/png,image/jpg,image/svg',
                    // maxSize: 2 * 1024,
                },
            }),
        };
        const formItem = {
            labelCol: { span: 4 },
            wrapperCol: { span: 8 },
        };

        return (
            <>
                <Form.Item
                    label={services.language.getText(
                        'salesPromotion.conditionsReward.chooseProduct'
                    )}
                    required
                    {...formItem}
                >
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <SelectAutoComplete
                            {...complementProps}
                            params={{
                                storeIds: (get(entity, 'fields.storeIds') || []).join(','),
                                page: 1,
                                perPage: 10,
                            }}
                            value={isEmpty(item.product) ? null : item.product}
                            apiPath={`/loader/dc-goods/admin/cskus/queryCskuByPage`}
                            selectParamKey='keyword'
                            onChange={(value: any) => {
                                onItemChange({ ...item, product: value });
                            }}
                            placeholder={services.language.getText('common.placeSelect')}
                        />
                        {formatValue.length > 1 && (
                            <Button onClick={() => onRemove(item)}>
                                {services.language.getText('common.delete')}
                            </Button>
                        )}
                    </div>
                </Form.Item>

                {Object.keys(fieldsConfig).map((field) => {
                    //@ts-ignore
                    const config = fieldsConfig[field];

                    if (!item[field] && config.initialValue) {
                        onItemChange({ ...item, [field]: config.initialValue });
                    }
                    if (
                        (field.match('skuClause') || []).length > 0 &&
                        !(
                            item.styleCommodityTerms &&
                            item.styleCommodityTerms !== STYLE_COMMODITY_TERMS_VALUES.NO
                        )
                    ) {
                        return null;
                    }

                    let props = {
                        ...config,
                        ...complementProps,

                        value:
                            field === 'exchangePurchasePrice'
                                ? item[field]
                                : item[field] || config.initialValue,
                        name: field,
                    };

                    if (config['type'] !== 'string.richText.video') {
                        props = {
                            ...props,
                            onChange: (val: any) => onItemChange({ ...item, [field]: val }),
                        };
                    } else {
                        props = {
                            ...props,
                            changeValue: (val: any, name: string, quillRef: any) => {
                                onItemChange({ ...item, [field]: val, quillRef });
                            },
                        };
                    }
                    return (
                        <Form.Item
                            {...formItem}
                            label={config['displayName']}
                            key={`${item.__uuid}${field}`}
                            required={config.required}
                        >
                            {TypesManager.get(config['type']).getControlComponent({
                                ...props,
                            })}
                        </Form.Item>
                    );
                })}
            </>
        );
    };
    return (
        <>
            {formatValue.map((item) => {
                return <div key={item.__uuid}>{renderItem(item)}</div>;
            })}
            {formatValue.length < 10 && (
                <Button type='primary' size='large' className='ant-col-offset-4' onClick={onAdd}>
                    {services.language.getText('add')}
                </Button>
            )}
        </>
    );
};

export const PromotionExchangeGroupProduct = React.forwardRef(PromotionExchangeGroupProductItem);
