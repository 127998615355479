import { PromotionExchangeGroupProduct } from '@/components/promotion-exchange-group-product';
import { modes } from '@comall-backend-builder/types';
import React from 'react';

import { formats } from '@comall-backend-builder/types';
import { every, isNumber } from 'lodash';
import { services } from '@comall-backend-builder/core';

export class PromotionExchangeGroupProductMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        let props = {
            ...controlInfo,
        };
        return <PromotionExchangeGroupProduct {...props} />;
    }
}

export class PromotionExchangeGroupProductFormat extends formats.ArrayFormat {
    /**
     * 对数据进行校验
     */
    validate(rule: any, value: any[], callback: any) {
        if (
            !every(
                value,
                (item) =>
                    item.exchangePurchasePrice !== null &&
                    item.exchangePurchasePrice !== '' &&
                    item.exchangePurchasePrice !== undefined &&
                    isNumber(Number(item.exchangePurchasePrice))
            )
        ) {
            callback(services.language.getText('salesPromotion.conditionsReward.exchangePrice'));
        }

        callback();
    }
}
