export default {
    common: {
        search: '查询',
        cancel: '取消',
        ok: '确定',
        reset: '重置',
        saveSuccess: '保存成功',
        saveFail: '保存失败',
        pleaseSelect: '请选择',
        pleaseUpload: '请上传',
        decorate: '装饰',
        edit: '编辑',
        delete: '删除',
        placeInput: '请输入',
        placeSelect: '请选择',
        modify: '修改',
        notStarted: '未开始',
        over: '已结束',
        open: '开启',
        close: '关闭',
        status: '状态',
        onlineStore: '线上门店',
        onlineStoreName: '线上门店名称',
        onlineStoreCode: '线上门店编号',
        keyword: '关键词',
        add: '新增',
        yes: '是',
        no: '否',
        handle: '操作',
        moveUp: '上移',
        moveDown: '下移',
    },
    Privilege: {
        text: '当前视图需要权限。',
    },
    errorMessages: {
        pleaseInputPositive: '请输入正整数',
    },
    productSourceType: {
        title: '商品来源',
        selfBuilt: '自建',
        standard: '标准',
        combination: '组合',
        virtual: '虚拟',
        exchange: '换购',
        giveaway: '赠品',
    },
    deliveryType: {
        title: '配送类型',
        pickUp: '自提',
        delivery: '配送',
        express: '快递',
        tc: {
            pickUp: '自提',
            delivery: '配送',
            express: '快遞',
        },
    },
    level: '会员等级',
    receiveAddress: '收货地址',
    weight: '重量',
    channels: {
        autoCancelEdit: {
            outOfStockOrderSwitch: '全部缺货订单自动取消开关',
            outOfStockOrder: '全部缺货订单',
            outOfStockOrderPrefix: '拣货完成后，第',
            outOfStockOrderSuffix: '天，当地时间09:00 AM 自动发起取消订单',
            pendingPickupOrderSwitch: '待提货订单自动取消开关',
            pendingPickupOrder: '待提货订单',
            pendingPickupOrderPrefix: '预计提货日期后，第',
            pendingPickupOrderSuffix: '天，当地时间09:00 AM 自动发起取消订单',
            on: '开启',
            off: '关闭',
            outOfStockOrderHelpTips:
                '（例如：设置3天，12月1日拣货完成：全部缺货，则12月4日早上9点自动发起取消订单）',
            outOfStockOrderInputRequired: '全部缺货订单不可为空',
            pendingPickupOrderHelpTips:
                '（例如：设置3天，12月1日为预计提货日期，若12月4日早上9点前未提货，自动发起取消订单）',
            pendingPickupOrderInputRequired: '待提货订单不可为空',
            inputErrorTips: '只可输入大于0的整数',
        },
        title: '渠道管理',
        childTitle: '管理渠道触点',
        channelName: '渠道名称',
        channelType: '渠道类型',
        view: '查看触点',
        autoCancelSetting: '自动取消订单设置',
    },
    up: '上移',
    down: '下移',
    set: '设置',
    yes: '是',
    no: '否',
    enabled: '启用',
    disabled: '禁用',
    enable: '启用',
    disable: '禁用',
    edit: '编辑',
    delete: '删除',
    actions: '操作',
    confirm: '确认',
    completed: '完成',
    cancel: '取消',
    submit: '提交',
    query: '查询',
    status: '状态',
    back: '返回',
    view: '查看',
    save: '保存',
    add: '新增',
    reset: '重置',
    all: '全部',
    copy: '复制',
    modifyFully: '修改成功',
    keyword: '关键词',
    organization: 'Division',
    merchant: '合作商户',
    successFully: '提交成功',
    saveFully: '保存成功',
    defaultPlaceholderSelect: '请选择',
    defaultPlaceholderInput: '请输入',
    download: '下载',
    downloadTemplate: '下载模板',
    internalSeverError: '服务器内部错误',
    uploadMessage: '上传成功',
    keywords: '关键词',
    mobile: '手机号',
    selectAll: '全选',
    export: '导出',
    import: '导入',
    numberPlaceHolder: '请输入数字',
    numberError: '请输入1~999999之间的整数',
    areYouSureToDelete: '确认删除吗?',
    cskuPlaceHolder: '多个SKU用空格隔开',
    rskuPlaceholder: '多个RSKU用空格隔开',
    upcPlaceholder: '多个UPC用空格隔开',
    styleIdPlaceholder: '多个StyleId用空格隔开',
    welcome: '欢迎登录',
    copyright: 'Powered by Beijing Co-Mall Internet Technology co., LTD',
    organizationNumber: '编号',
    recordToast: '导入成功，请稍后在【日志管理-导入日志管理】中查看结果',
    to: 'To',
    toCn: '至',
    columnSetting: '列设置',
    columnDisplay: '列展示',
    components: {
        UploadFile: {
            uploadCompleteMessage: 'Uploaded Successfully',
            uploadLimitText: 'Max',
        },
        OrderReceipt: {
            receiptNumber: '收據編號',
            paymentStatus: '付款狀態',
            paid: '已付款',
            unpaid: '未付款',
            subscriber: '訂購人',
            deliveryMethod: '送貨方式',
            orderEmail: '訂單EMAIL',
            telNumber: '電話號碼',
            transportTo: '運送至',
            paymentMethod: '付款方式',
            discountCoupon: '優惠券',
            redemptionNote: '兌換券',
            addressee: '收件人',
            totalAmount: '商品總額',
            paidPayTotalAmount: '實付總金額',
            goods: '商品',
            goodsNumber: '商品貨號',
            number: '數量',
            price: '價格',
            subtotal: '小計',
            freight: '運費/服務費',
            promotionDiscount: '促銷優惠',
            freightDiscount: '運費優惠',
            integral: '積分',
            lack: '缺貨',
            partLack: '部分缺貨',
            lackTips: '訂單缺貨產品之款項將於下星期一退還,敬請留意',
            expectedDeliveryTime: '期望送達時間',
            remark: '備註',
            footerTitle: '棄置電器除舊服務',
            footerContent:
                '當顧客選購四電⼀腦，可選⽤法定免費除舊服務*，收集屬同類受管制電器。在緊急情況下或因應顧客的除舊服務要求，個別品牌。\n\n供應商會為顧客提供額外收費升級除舊服務，品牌供應商將安排於送貨當⽇於同⼀地址移除舊機。選⽤升級除舊服務之顧客必須於供應商送貨隊伍移除舊機時即場以現⾦⽀付 (如適⽤)，詳情請向供應商直接查詢。\n\n*法定免費除舊服務由環保署委託回收商歐綠保綜合環保（香港）有限公司（「歐綠保」）提供。本網站所列的產品，是《產品環保責任條例》(第603章) 所指的受管制電器。該條例就該產品徵收下列循環再造徵費，已經就該產品徵收循環再造徵費如下，顧客不需再另⾏付費：空調機：每部$125 | 抽濕機：每部$125 | 洗衣機：每部$125  | 電冰箱：每部$165 | 電視機：每部$165 | 電腦：每部$15 | 列印機：每部$15 | 掃瞄器：每部$15 | 顯⽰器：每部$45 | 乾衣機：每部$125。',
        },
        PackageTags: {
            orderNumber: '訂單編號',
            dryOrder: '乾貨運單',
            customer: '下單客戶',
            address: '收貨地址',
            frozenOrder: '凍貨運單',
            deliveryDate: '執貨日期',
            shippingDate: '出貨日期',
            deliveryType: '配送類型',
            onlyDry: '有乾貨',
            onlyFrozen: '有凍貨',
            hasFrozenDry: '有乾貨 + 有凍貨',
            dryUnits: '乾貨件數',
            frozenUnits: '凍貨件數',
        },
        PrintGoodsTagsButton: {
            buttonText: '打印价签',
        },
        GoodsTags: {
            suggestPrice: '建議零售價',
            expires: '保養期',
            size: '尺寸',
            brand: '品牌',
            mm: '毫米',
        },
        CouponGroup: {
            couponName: '优惠券名称',
            addCoupon: '添加优惠券',
            couponList: '优惠券列表',
            effectiveStartTime: '生效开始时间',
            effectiveEndTime: '生效结束时间',
            number: '数量',
        },
    },
    week: {
        monday: '星期一',
        tuesday: '星期二',
        wednesday: '星期三',
        thursday: '星期四',
        friday: '星期五',
        saturday: '星期六',
        sunday: '星期日',
    },
    minutes: '分钟',
    days: '天',
    table: {
        total: '共 {{total}} 条记录',
    },
    statusActions: {
        disable: '禁用',
        enable: '启用',
    },
    statusValue: {
        disabled: '禁用',
        enabled: '启用',
    },
    statusTypeValue: {
        ineffective: '未生效',
        disable: '已失效',
        enable: '已生效',
    },
    expand: '展开',
    collapse: '收起',
    close: '关闭',
    exportModal: {
        pleaseSelect: '请选择要导出的数据',
        exportSuccessMsg: '导出操作已生成，稍后请在 日志管理-导出文件管理中查看。',
        emptyText: '没有需要导出的信息，请您调整搜索条件后重新导出',
        loadingText: '正在查询, 请稍后...',
        confirmText: '确认导出',
    },
    organizationSelectorModal: {
        modalTitle: '选择Division',
        pleaseSelect: '请选择Division',
    },
    storeSelectorModal: {
        modalTitle: '选择门店',
        pleaseSelect: '请选择门店',
        displayStoreName: '已选 {{count}} 家门店',
    },
    home: {
        title: '首页',
    },
    productManage: {
        title: '商品管理',
    },
    login: {
        tip: '您没有访问权限，请联系管理员授予访问权限',
        display: '登录',
        username: {
            display: '账号',
            errMsg: '账号错误',
            placeholder: '请输入你的账号',
        },
        password: {
            display: '密码',
            placeholder: '请输入你的密码',
        },
    },

    productPrice: {
        title: '商品价格管理',
        monitoring: '商品价格监控',
        productPriceEdit: '编辑商品价格',
        keyWords: '关键词',
        keyWordsPlaceHolder: '商品名称',
        specsType: '规格类型',
        sourceType: '商品来源',
        middleCurrentPrice: '中台价格',
        name: '商品名称',
        cskuName: '商品名称（简体中文）',
        cskuNameTc: '商品名称（繁体中文）',
        cskuNameEn: '商品名称（英文）',
        sku: '商品SKU',
        enName: '商品名称',
        barcode: '商品条码',
        organization: 'Division',
        price: '商品现售价',
        locationIdPrice: '默认价格',
        suggestedRetailPrice: '建议零售价',
        costPrice: '成本价',
        promotionPrice: 'duty free 促销价',
        dutyPaidPrice: 'duty paid 原价',
        dutyPaidPromotionPrice: 'duty paid 促销价',
        taxCode: 'Tax code',
        lastedModifyTime: '更新时间',
        lastedModifyUser: '更新人',
        interCurrentPrice: '接口价格',
        interLastedModifyTime: '接口更新时间',
        ISOCode: '币种',
        export: '导出商品价格',
        ppu: '原价PPU',
        promotionPpu: '促销价PPU',
        salePrice: '销售价格',
        changeCause: '变动原因',
        changeTime: '变动时间',
        Operator: '操作人',
        priceChangeLog: '价格变动日志',
        type: {
            normal: '标准',
            create: '新建',
            group: '组合',
            virtual: '虚拟',
        },
        specifications: {
            common: '普通商品',
            weighing: '称重商品',
        },
        section: '区间',
        importPrice: '导入商品价格',
        exportTemplate: '下载导入模板',
        priceError: '请填写8位整数，2位小数的有效数字',
        priceEmpty: '价格不能位空',
        merchant: '合作商户',
        exportProductPrice: '导出商品价格',
    },
    productTag: {
        title: '商品标签管理',
        detail: '查看商品标签',
        productTag: '商品标签',
        productBasicTag: '打标组标签',
        productAPToolTag: '促销标签',
        productTagName: '商品标签名称',
        tagName: '促销标签名称',
        priorityLevel: 'DC优先级',
        promotionType: '促销类型',
        promotionRule: '促销规则',
        promotionMode: '促销方式',
        beginTime: '开始时间',
        endTime: '结束时间',
        viewProduct: '查看',
        apToolDisabledTagTip: '确认禁用促销标签？',
        isDelete: '确认删除商品标签',
        productList: '商品列表',
        productBrand: '商品品牌',
        productCategory: '商品分类',
        productException: '例外商品',
        productExclusion: '例外商品',
        pleaseInputTagName: '请输入商品标签名称',
        pleaseInputPriority: '请输入优先级',
        uploadProducts: '上传商品',
        uploadTip: '提示：请选择并上传要导入的商品（支持xls、xlsx、csv文件）',
        uploadPlaceholder: '请输入SKU编号,并用“;”分隔，或批量上传商品信息',
        pleaseSelectBrand: '请选择品牌',
        pleaseSelectTagType: '请选择配置类型',
        pleaseSelectCategory: '请选择分类',
        brandNameEn: '品牌名称（英文）',
        brandNameZn: '品牌名称（中文）',
        pleaseInputSearch: '请输入查询内容',
        cskuIdsNotEmpty: '商品列表不能为空',
        brandIdsNotEmpty: '商品品牌不能为空',
        categoryIdsNotEmpty: '商品分类不能为空',
        breadcrumb: {
            addTag: '新增商品标签',
            editTag: '编辑商品标签',
            viewTag: '查看商品标签',
        },
        levelTip: '数字越大优先级越高。建议使用100,200等间隔较大的数字，方便后期维护',
        configType: {
            title: '配置类型',
            goodsList: '商品列表',
            goodsBrand: '商品品牌',
            goodsCategory: '商品分类',
        },
        deleteTip: '删除后对应的打标组也将全部删除',
        inputArray: {
            emptyTip: '商品标签名称不可为空',
            lengthTip: '商品标签名称必须为1~50个字符',
        },
        productTagNameTip: '商品标签名称(请不要超过建议字数: EN//FR/IT: 23；SC/TC/JP/KR: 10)',
        customer: '适用人群',
        staff: '员工',
        normal: '普通用户',
        cartDisplay: '是否在购物袋显示',
    },
    storeManage: {
        title: '门店管理',
    },
    onlineStore: {
        couponRetailStore: '优惠券来源线下门店',
        title: '线上门店管理',
        addStore: '新增门店',
        editStore: '编辑线上门店',
        createStoreChannel: '对接门店渠道',
        viewStore: '查看线上门店',
        keyWords: '关键词',
        merchant: '合作商户',
        keyWordsPlaceHolder: 'Online Store Name',
        storeName: '线上门店名称',
        storeEnglishName: 'Name Of Online Store',
        storeStatus: '门店状态',
        organization: 'Division',
        storeCode: '线上门店编号',
        timeRangeError: '结束时间不能早于开始时间',
        noMoreChannel: '没有可对接的渠道',
        retailStore: '促销来源线下门店',
        priceStore: '价格来源线下门店',
        sapMcsLocation: 'SAP 授权MCS Location',
        stockMcsLocation: '线上门店库存来源MCS Location',
        storeLogo: '门店logo',
        keywordPlaceholder: '线上门店名称//编号',
        eshop: '导购店是否有对应Eshop店：',
        noEshop: '导购店无对应Eshop店',
        hasEshop: '导购店有对应Eshop店',
        storeNameError: '线上门店名称不能超过100字',
        payment: '支付方式',
        currencyIsoCode: '货币',
        orderOwn: '订单归属门店',
        namePlaceholder: '请输入线上门店名称',
        storeNameNotEmpty: '线上门店名称不能为空',
        brand: '特殊提货要求的品牌',
        sa: '订单默认归属SA',
        LatestDateOfDeparture: '最晚离境日期',
        DateOfOrder: '下单日期的',
        daysAfter: '天后',
        warningData: '最晚离境日期应输入1~999999之间的整数',
        shippingMethods: {
            title: '运输方式',
            courier: '快递',
            pickUp: '自提',
            distribution: '配送',
        },
        businessType: '业态',
        storeAttribute: '门店属性',
    },
    pickStore: {
        title: '提货点管理',
        storeName: '提货门店名称',
        editStoreTitle: '编辑提货点',
        addStoreTitle: '新增提货点',
        createOnlineStore: '对接线上门店',
        viewStore: '查看提货点',
        retailStoreCode: '站点 ID',
        terminalId: 'Terminal ID',
        organization: 'Division',
        addStore: '新增',
        keywordPlaceHolder: '提货门店名称// ID',
        retailStoreCodePlaceholder: '请输入站点ID',
        storeNamePlaceholder: '请输入门店名称',
        terminalIdPlaceholder: '请输入Terminal ID',
        storeCodeNotEmpty: 'Retail Store ID不能为空',
        storeCodeLenError: 'Retail Store ID不能超过20字',
        storeCodeNumError: 'Retail Store ID只能是数字',
        terminalIdNotEmpty: 'Terminal ID不能为空',
        terminalIdNumError: 'Terminal ID只能是数字',
        terminalIdLenError: 'Terminal ID不能超过20字',
        longitudeLenError: '提货点经度不能超过18字',
        latitudeLenError: '提货点纬度不能超过18字',
        pickStoreNameError: '提货门店名称不能超过100字',
        pickStoreNamePlaceholder: '请输入提货门店名称',
    },
    pickStoreInfo: {
        title: '查看提货点',
        storeInfo: '提货点信息',
        code: '提货点编号',
        storeName: '提货点名称',
        storeStatus: '提货点状态',
        organization: 'Division',
        pickingType: '主拣货方式',
        type: '提货点类型',
        contactInformation: '联系方式',
        contacts: '提货点联系人',
        addressInfo: '地址信息',
        storeAddress: '提货点地址',
        realityImages: '提货点实景图',
        masterMobile: '提货点电话',
        backupMobile: '备联电话',
        limitText: '最多上传',
        limitUnit: '张',
        storeCodeError: '提货点编号不能为空',
        storeNameError: '提货点名称不能为空',
        masterMobileError: '提货点电话不能为空',
        storeAddressError: '提货点地址不能为空',
        getStoreInfo: '获取提货点信息',
        hasStore: '提货点已存在',
        storeNamePlaceHolder: '请输入提货点名称',
        codePlaceHolder: '请输入提货点编号',
        typePlaceHolder: '首选提货点//普通提货点',
        contactsPlaceHolder: '请输入联系人姓名',
        masterMobilePlaceHolder: '请输入电话号码',
        backupMobilePlaceHolder: '请输入手机号码',
        address: 'Pickup Store Address',
        addressPlaceHolder: '请输入门店地址',
        latitude: '提货点纬度',
        latitudePlaceHolder: '请输入门店纬度',
        longitude: '提货点经度',
        longitudePlaceHolder: '请输入门店经度',
        timeError: '时间范围不能交叉',
        pickUpError: '提货单数不可为空',
        canQuantity: '可提货单数',
        quantity: '单',
        quantityNumber: '可提货订单数只可输入整数',
        storeCodeLenError: 'Retail Store ID只能为数字，且长度不能超过50',
        storeNameLenError: '提货点名称不能超过50字',
        contactsLenError: '提货点联系人不能超过100字',
        masterMobileLenError: '提货点电话不能超过100字',
        storeAddressLenError: '提货点地址不能超过200字',
        allTab: '全部',
        storeAddressEmpty: '提货门店地址不能为空',
        pickupPointAttributes: '提货点属性',
        activeMembershipLevel: '生效会员等级',
    },
    storeInfo: {
        title: '查看门店',
        storeInfo: '门店信息',
        code: '门店编号',
        storeName: '门店名称',
        storeStatus: '门店状态',
        organization: 'Division',
        pickingType: '主拣货方式',
        type: '门店类型',
        contactInformation: '联系方式',
        contacts: '联系人',
        addressInfo: '地址信息',
        storeAddress: '门店地址',
        realityImages: '门店实景图',
        masterMobile: '门店电话',
        backupMobile: '备联电话',
        limitText: '最多上传',
        limitUnit: '张',
        storeCodeError: '门店编号不能为空',
        storeNameError: '门店名称不能为空',
        masterMobileError: '门店电话不能为空',
        storeAddressError: '门店地址不能为空',
        getStoreInfo: '获取门店信息',
        hasStore: '门店已存在',
        storeNamePlaceHolder: '请输入门店名称',
        codePlaceHolder: '请输入门店编号',
        typePlaceHolder: '首选门店//普通门店',
        contactsPlaceHolder: '联系人姓名',
        masterMobilePlaceHolder: '请输入电话号码',
        backupMobilePlaceHolder: '请输入手机号码',
        address: '门店地址',
        addressPlaceHolder: '请输入门店地址',
        latitude: '门店纬度',
        latitudePlaceHolder: '请输入门店纬度',
        longitude: '门店经度',
        longitudePlaceHolder: '请输入门店经度',
        timeError: '时间范围不能交叉',
        pickUpError: '提货单数不可为空',
        canQuantity: '可提货单数',
        quantity: '单',
        quantityNumber: '可提货订单数只可输入整数',
        picking: {
            market: '卖场拣货',
            front: '前置仓拣货',
        },
        nameError: '门店名称不能超过{{max}}个字符',
        pickStoreType: '提货门店类型',
        inStore: '自提',
        delivery: '配送',
        minDeliveryAmount: '最低配送金额',
        minDeliveryAmountPlaceHolder: '请输入最低配送金额',
        minDeliveryAmountRules: '最低配送金额不能为空',
        loyalTNumber: 'Loyal T Number是否必填',
    },
    pickOnlineStore: {
        title: '提货点上线管理',
        editStoreTitle: '编辑提货点上线',
        addStoreTitle: '新增提货点',
        keywordPlaceholder: '提货门店名称//ID',
        onlineStore: '线上门店',
        storeEnglishName: 'Online Store Name',
        serviceStatus: '营业状态',
        storeName: '提货点门店名称',
        storeNameError: '提货点名称不能超过100字',
        retailStoreId: '站点 ID',
        opening: '营业中',
        closing: '休息中',
        onlineStoreSelect: '线上门店',
        sort: '排序',
        sortNotEmpty: '顺序不能为空',
        pickStore: '提货点门店名称',
        pickRetailStoreId: '站点 ID',
        supportAirPickup: '是否支持机场提货',
        storeDeadlineTime: '每日店内付款截止时间',
        deliveryTime: '每日最早送货到达机场时间',
        dutyFreeDeadlineTime: 'Duty free订单截止时间',
        generalPickupPoint: '普通提货点',
        specialPickupPoint: '特殊提货点',
        generalStore: '普通门店',
        specialStore: '特殊门店',
    },
    storeChannels: {
        title: '门店渠道管理',
        editStoreChannel: '编辑门店渠道',
        storeName: '门店名称',
        storeCode: '门店编号',
        channelIds: '选择渠道',
        outerCode: '渠道店号',
        channelName: '渠道名称',
        channelStatus: '门店渠道状态',
        name: '渠道展示名称',
        nameTip: '门店渠道展示名称不能超过20字',
        nameNoEmpty: '渠道展示名称不能为空',
        announcement: '公告信息',
        announcementTip: '公告信息不能超过100个字符',
        businessTimes: '营业时间',
        businessTimesAndCapacity: '营业时间及运力',
        orderWay: '接单方式',
        serviceStatus: '营业状态',
        earliestDeliveryTime: '最早提货日期',
        deliveryDate: '最晚提货日期',
        pickUpTimes: '拣货时间',
        earliestDeliveryTimeTip: '后可提货',
        DeliveryDayTip: '天内',
        from: '至',
        day: '天',
        opening: '营业中',
        closeing: '休息中',
        minutes: '分钟',
        successFully: '提货点上线成功',
        earliestDeliveryTimeError: '最早提货日期不能为空',
        pickUpTime: '当日可提货时间',
        fastestDate: '最快提货日期',
        orderDeadlineTime: '截单时间',
        afterDay: '天后',
        orderDate: '下单日期',
        maxQuantityOfDay: '门店每日最大可提货单数',
        brand: '特殊提货要求的品牌',
        brandPlaceholder: '请选择品牌',
        numberError: '提货日期应输入1~999999之间的整数',
        numberError0: '最快提货日期应输入0~999999之间的整数',
        deliveryDateError: '最快提货日期不能大于最晚提货日期',
        startTime: '开始日期',
        endTime: '结束日期',
        add: '增加',
        dischargeTime: '营业时间排除',
        selectDischargeTime: '选择排出营业日期',
        warning: '请选择时间',
    },
    retailStore: {
        offlineStoreMsg: '线下门店信息',
        title: '线下门店管理',
        editStoreTitle: '编辑线下门店',
        viewStore: '查看线下门店',
        storeName: '线下门店名称',
        retailId: '站点 ID',
        organization: 'Division',
        keywordPlaceHolder: '线下门店名称//ID',
        mcsLocationIds: 'MCS Location',
        storeNameError: '线下门店名称不能超过100字',
        code: '线下门店编号',
        storeNamePlaceholder: '请输入线下门店名称',
        storeNameNotEmpty: '线下门店名称不能为空',
        deliveryMaxNumber: '门店每日最大可提货单数',
        error: '可提货单数总和不可超过门店每日最大可提货单数',
        deliveryDayError: '最快提货日期应输入整数',
        isShow: '是否对外展示',
        contactInformation: '联系方式',
        contactName: '线下门店联系人',
        masterMobile: '线下门店电话',
        contacts: '线下门店联系人',
        contactsPlaceHolder: '请输入联系人姓名',
        masterMobilePlaceHolder: '请输入电话号码',
        backupMobilePlaceHolder: '请输入手机号码',
        contactsLenError: '线下门店联系人不能超过100字',
        masterMobileLenError: '线下门店电话不能超过100字',
        masterMobileError: '请输入正确的电话',
        longitude: '线下门店经度',
        latitude: '线下门店纬度',
        latitudePlaceHolder: '请输入线下门店纬度',
        longitudePlaceHolder: '请输入线下门店经度',
        longitudeLenError: '提货点经度不能超过18字',
        latitudeLenError: '提货点纬度不能超过18字',
        addressInfo: '地址信息',
        storeAddressLenError: '线下门店地址不能超过200字',
        address: '线下门店地址',
        addressPlaceHolder: '请输入线下门店地址',
        realisticPicture: '线下本店实景图',
        picLimitSize: '线下本店实景图文件大小不能超过5M',
        paid: '完全支付',
    },
    businessCooperator: {
        title: '合作商户管理',
        editMerchantsStore: '编辑合作商户',
        viewMerchantsStore: '查看合作商户',
        merchantsName: '合作商户名称',
        placeHolder: '请填写',
        merchantsId: '合作商户ID',
        keyWords: '关键词',
        namePlaceholder: '请填写',
        nameNotEmpty: '合作商户名称不能为空',
        nameError: '合作商户名称不能超过100字',
        deliveryError: '送货信息不能超过100字',
        emailMsgError: '订单接收邮箱不能超过100字',
        keyWordsPlaceHolder: '名称//编号',
        deliveryInformation: '送货信息',
        email: '订单接收邮箱',
        emailPlaceholder: '请填写接收邮箱，多个用 ；分隔',
        logoLimitSize: 'logo文件大小不能超过5M',
        errEmail: '请填写正确邮箱格式',
        logo: '合作商户Logo',
        keywordPlaceholder: '合作商户名称//编号',
        mpBanner: 'MP商户banner',
        pcBanner: 'PC商户banner',
        jumpLink: '跳转链接',
        linkPlaceholder: '请输入"https://"开头链接',
        deliveryExplain: '运输方式说明（配送）',
        expressExplain: '运输方式说明（快递）',
        descriptionMap: '商户介绍',
        banner: '商户banner',
        merchantBannerError: '请填写完整的商户banner',
        recommendProduct: '推荐商品管理',
        updateCategory: '分类刷新',
        updateSuccessMsg: '刷新成功,请稍后查看结果',
        updateErrorMsg: '刷新失败,请重新刷新',
    },
    systemManage: {
        title: '系统管理',
    },
    organizations: {
        title: 'Division管理',
        name: 'Division名称',
        language: '默认语言',
        timeZone: '时区',
        currencyType: '默认币种',
    },
    channelContacts: {
        title: '渠道触点管理',
        contactName: '触点名称',
        contactType: '触点类型',
        merchant: '合作商户',
    },
    upload: {
        triggerText: '上传文件',
        sizeLimit: '上传文件不能超过{{size}}M',
        success: '上传成功',
    },
    categoryManage: {
        title: '品类管理',
    },
    brands: {
        title: '品牌管理',
        keyWords: '关键词',
        keyWordsPlaceHolder: '品牌名称//品牌编码',
        brandStatus: '品牌状态',
        brandCode: '品牌编码',
        brandName: '品牌名称',
        status: '状态',
        viewStore: '查看品牌详情',
        brandDescription: '品牌故事',
        topLogo: 'Top品牌logo',
        featuredLogo: 'Featured 品牌logo',
        bottomLogo: 'Bottom品牌logo',
        brandChannel: '对接渠道',
        brandType: '品牌类型',
        brandSource: '品牌来源',
        brandRules: '对应规则',
        brand: '品牌',
        brandAdd: '新增品牌',
        importInfo: '导入品牌信息',
        brandInfo: '品牌信息',
        exportInfo: '下载导入模板',
        channelTitle: '对接渠道',
        confirmDeleteBrand: '确认删除品牌么？',
        deleteTip: '删除对应品牌后将无法撤回',
        type: {
            normal: '标准',
            create: '新建',
        },
        recommendProduct: '推荐商品管理',
    },
    virtualCategory: {
        navTitle: '虚拟分类管理',
        keyWords: '关键词',
        keyWordsPlaceholder: '分类ID//分类名称',
        add: '新增分类',
        edit: '编辑虚拟分类',
        downloadTemplate: '下载导入模板',
        name: '虚拟分类名称',
        nameTips: '虚拟分类名称(请不要超过建议字数: EN//FR/IT: 50；SC/TC/JP/KR: 15)',
        namePlaceholder: '请输入虚拟分类名称',
        nameRequired: '虚拟分类名称不能为空',
        id: '虚拟分类ID',
        parentId: '父级分类',
        importProduct: '导入商品',
        exportProduct: '导出商品',
        productNumber: '商品数量',
        viewProduct: '查看商品',
        productList: '商品列表',
        desc: '虚拟分类描述',
        descPlaceholder: '请输入虚拟分类描述',
        picture: 'MP 虚拟分类图片',
        picturePC: 'PC 虚拟分类图片',
        imageOverstep: '图片大小不得超过5M',
        importProductTip: '商品导入成功，请稍后在【日志管理-导入日志管理】中查看结果',
    },
    displayCategory: {
        title: '展示分类管理',
        keyWords: '关键词',
        keyWordsPlaceholder: '分类ID//分类名称',
        add: '添加展示分类',
        edit: '编辑展示分类',
        parentId: '父级分类',
        saleCategories: '映射标准分类',
        virtualCategories: '映射虚拟分类',
        categoryRequired: '标准分类、虚拟分类至少填一个',
        desc: '分类描述',
        descPlaceholder: '请输入分类描述',
        picture: 'MP 分类图片',
        pcPicture: 'PC 分类图片',
        bannerPicturePath: 'MP 分类Banner',
        pcBannerPicturePath: 'PC 分类Banner',
        bannerUrl: '跳转链接',
        bannerPlaceholder: '请输入"https:///"开头链接',
        name: '展示分类名称',
        namePlaceholder: '请输入展示分类名称',
        nameRequired: '展示分类名称不能为空',
        categoryId: '展示分类ID',
        status: '状态',
        order: '顺序',
        effectiveTime: '生效时间',
        orderPlaceholder: '请输入数字',
        exportProduct: '导出商品',
        displayFirstLevelCategory: '是否展示所有分类项',
        redirectSexFilter: '跳转性别筛选项',
        nameTips: '展示分类名称(请不要超过建议字数: EN//FR/IT:50；SC/TC/JP/KR: 15)',
        errMsg: {
            detailMaxLength: '分类描述不能超过500字符',
            nameMaxLength: '分类名称不能超过50字符',
        },
        recommendProduct: '推荐商品管理',
        enable: '启用',
        forbidden: '禁用',
        all: '全部',
        exportDisplay: '导出展示分类',
        importDisplay: '导入展示分类',
    },
    logout: '登出',
    productLimit: {
        title: '商品限购管理',
        categoryName: '分类名称',
        categoryLimitCount: '分类限购数',
        productLimitCount: '商品限购数',
        singleProductCount: '单品限购数',
        editLimitTip: '编辑限购提示',
        editLimitTitle: '编辑限购提示',
        editProductLimit: '编辑商品限购',
        viewLimitTip: '查看限购提示',
        categoryLimitTip: '分类限购提示',
        singleProductLimitTip: '单品限购提示',
        productBrand: '商品品牌',
        type: '限购类别',
        limitNum: '限购件数',
        singleLimitNum: '单品限购数量',
        brandLimitNum: '品牌限购数量',
        maxNumber: '最大输入6位正整数',
    },
    blacklist: {
        title: '商品黑名单管理',
        setBrand: '设置品牌',
        brand: '品牌',
        total: '总计',
        configBrand: '配置品牌',
        productList: '商品清单',
        productBlacklist: '商品黑名单',
        productBrand: '商品品牌',
        blacklistBrandName: '黑名单品牌名称',
        blacklistReason: '黑名单原因',
        createTime: '创建时间',
        creator: '创建人',
        lastModifiedTime: '最后修改时间',
        isDelete: '确认删除黑名单',
        inputReason: '请输入原因',
        viewTitle: '查看商品黑名单',
        addTitle: '新增商品黑名单',
        editTitle: '编辑商品黑名单',
        importBlacklistButton: '导入黑名单商品',
        importDeleteBlacklistButton: '导入移除商品',
        downloadImportTemplate: '下载导入模板',
        exportBlacklistButton: '导出黑名单商品',
        heroImagePath: '商品主图',
        barCode: '商品条码',
        csku: 'SKU',
        name: '商品名称',
        brandName: '品牌名称',
        productlastModifiedTime: '更新时间',
        removeBlackListProduct: '移除黑名单商品',
        blackListProduct: '黑名单商品',
    },
    productInventory: {
        title: '商品库存管理',
        monitoring: '商品库存监控',
        keyWords: '关键词',
        // sourceType: '库存来源',
        // edit: '编辑分类安全库存',
        keyWordsPlaceHolder: '商品名称',
        specsType: '规格类型',
        sourceType: '商品来源',
        productSKU: '商品SKU',
        edit: '编辑商品库存',
        productName: '商品名称',
        childProductName: '子商品名称',
        groupProduct: '组合商品',
        groupNumber: '组合数量',
        productBarCode: 'UPC',
        organization: 'Division',
        surplusQuantity: '剩余库存',
        existQuantity: '现货库存',
        safeQuantity: '安全库存',
        reserveQuantity: '预占库存',
        warnQuantity: '预警库存',
        warrantyPeriod: '预警保质期',
        saleableQuantity: '可卖数',
        lastedModifyTime: '更新时间',
        importSafeQuantity: '导入商品安全库存',
        importSupplement: '导入补充库存',
        exportSafeQuantity: '导出商品安全库存',
        exportTemplate: '下载导入模板',
        exportQuantity: '导出商品库存',
        areYouSureToExport: '是否要导出商品？',
        store: '线上门店',
        saleCategory: '标准分类',
        categoryName: '分类名称',
        categoryCode: '分类ID',
        goodsSafeStock: '普通商品安全库存',
        giftSafeStock: '赠品安全库存',
        keywordPlaceHolder: '分类ID//分类名称',
        organizationNo: 'Division编码',
        organizationName: 'Division名称',
        goodsSafeStockName: '商品安全库存',
        stockManage: '库存管理',
        goodsSafeStockManage: '商品安全库存管理',
        categorySafeStockManage: '分类安全库存管理',
        exportError: '请至少勾选一个商品',
        search: '查询',
        changeLog: '库存变动日志',
        batchNo: '批次号',
        batchTotalQuantity: '现货库存',
        changeQuantity: '变动数量',
        changeReason: '扣减原因',
        changeTime: '变动时间',
        changeType: '变动内容',
        operator: '操作人',
        deductions: '扣减库存',
        deductionsNumber: '库存扣减数',
        supplement: '补充库存',
        supplementMessage: '请填写补充库存',
        expirationDate: '质保期',
        attribution: '库存归属MCS Location',
        someQuantity: '销售/预占/扣减/过期',
        deducetReason: '扣减原因',
        nearOverdue: '临近过期',
        remark: '备注',
        type: {
            normal: '标准',
            create: '新建',
            group: '组合',
        },
        common: '普通商品',
        weighing: '称重商品',
        updateSpotInventory: '更新现货库存',
    },
    productGroup: {
        title: '商品关联管理',
        add: '新增商品关联',
        edit: '编辑商品关联',
        name: '关联商品组名称',
        count: '商品数量',
        modifyTime: '更新时间',
        modifyUser: '更新人',
        namePlaceholder: '请输入商品名称',
        groupNameError: '请输入商品关联组名称',
        csku: 'SKU',
        styleName: '款式名称',
        selectStyle: '选择款式',
        styleError: '商品款式不能为空',
        createStyle: '一键生成款式',
        groupName: '商品关联组名称',
        groupProductError: '请配置关联商品',
        product: '商品',
        productName: '商品名称',
        cskuPlaceholder: '请输入SKU',
        sort: '商品排序',
    },
    members: {
        memberManagement: '提货人管理',
        memberList: '提货人列表',
        keyWordsPlaceHolder: '姓氏//名字/手机号',
        region: 'Division',
        firstName: '名字',
        lastName: '姓氏',
        title: '称谓',
        source: '客户来源',
    },
    offlineCategory: {
        title: '标准分类管理',
        name: '分类名称',
        categoryId: '分类ID',
        specialAttribute: '设置分类特有属性',
        specialAttributeTip:
            '已保存的特有属性删除后，该分类下所有商品的对应的对应属性均会同步删除，请谨慎处理；',
        categoryOrCode: '分类ID//分类名称',
        keyWords: '关键词',
        attribute: '属性',
        sort: '排序',
    },
    privilegeManage: {
        title: '权限管理',
        users: {
            title: '用户管理',
            addUser: '添加用户',
            editUser: '编辑用户',
            keyword: '关键词',
            keywordPlaceHolder: '姓名//Email',
            status: '用户状态',
            realName: '姓名',
            realNamePlaceholder: '请输入姓名',
            realNameNoEmpty: '姓名不能为空',
            realNameNoLength: '姓名必须为1~50字符',
            jobNumber: '工号',
            jobNumberNoEmpty: '工号不能为空',
            jobNumberNoLength: '工号必须为1~50字符',
            jobNumberPlaceholder: '请输入员工号',
            userName: '账号名',
            userNamePlaceholder: '请输入登录名',
            userNameNoLength: '账号名必须为1~50字符',
            roles: '所属角色',
            organizations: '所属Division',
            merchant: '合作商户',
            organization: 'Division',
            emai: '邮箱',
            emailPlaceholder: '请输入邮箱',
            emailNoEmpty: '邮箱不能为空',
            emailError: '请输入正确的邮箱',
            password: '密码',
            confirmPassword: '确认密码',
            confirmPasswordRule: '兩次密碼不一致',
            passwordPlaceholder: '请输入新密码',
            confirmPasswordPlaceholder: '请输入确认密码',
            passwordNoLength: '密码必须为6~20字符',
            resetPassword: '重置密码',
            changePasswordSuccess: '修改成功',
            onlineStore: '线上门店',
            ownChannel: '所属渠道',
            ownOnlineStore: '所属线上门店',
            ownMerchant: '所属合作商户',
            retailStore: '所属拣货门店',
            email: 'Email',
            loginPasswordPlaceholder: '请输入密码',
            loginPasswordEmpty: '密码不能为空',
            passwordRule: '长度在8到20个字符内，必须包含至少一个数字，一个符号，一个大写及小写字母',
        },
        roles: {
            title: '角色管理',
            addRole: '添加角色',
            editRole: '编辑角色',
            keywordPlaceHolder: '角色名称',
            roleName: '角色名',
            roleNamePlaceholder: '例如：管理员',
            roleNameNoLength: '角色名必须为1~50字符',
            roleDesc: '角色描述',
            roleDescPlaceholder: '请输入',
            roleDescNoLength: '角色描述必须为1~500字符',
            status: '角色状态',
            setting: '权限设置',
            menuIdsRequired: '请选择权限',
            module: '模块',
            page: '页面',
            operation: '操作',
        },
    },
    orderManage: {
        title: '交易管理',
        selfHarvesting: '自提收货',
    },
    order: {
        title: '订单管理',
        createTime: '下单时间',
        orderStatus: '订单状态',
        stockOut: '是否缺货',
        payType: '支付方式',
        waitDeliveryTime: '预计提货日期',
        outerOrderNumber: '订单编号',
        member: '下单客户',
        exportOrder: '导出订单报表',
        exportOrderDetail: '导出订单详情',
        orderNumber: '原订单号',
        cancelOrder: '取消订单',
        orderCancelSuccess: '订单取消成功',
        conformCancelOrder: '确认取消订单',
        pleaseSelectCancelOrderReason: '请选择取消原因',
        cancelOrderDescPlaceholder: '如有更多信息请输入',
        pickUpDateButton: '修改订单',
        pickDateLabel: '选择日期',
        pickTimeLabel: '选择时间',
        pickDate: '请选择日期',
        pickTime: '请选择时间',
        resendOrderTitle: '确认重新向MCS下发订单？',
        resendOrderTip: '已重新向MCS下发订单，可能需要一些时间，请您稍后刷新订单查看结果',
        confirmPickup: '确认提货',
        confirmPickupTitle: '确认订单提货?',
        confirmPickupContent: '请确认顾客已在门店提货。确认提货后订单不可取消，只可发起退货退款',
        resendOrder: '重发订单',
        reReturnOrder: 'T重发退款请求',
        reReturnOrderTitle: '确认重发退款请求',
        reReturnOrderContent: '继续向PSP发起退款请求',
        reReturnOrderSuccess: '已重新向PSP发起退款请求，可能需要一些时间，请您稍后刷新订单查看结果',
        returnOrder: 'T已完成退款',
        returnOrderTitle: '确认已完成退款',
        returnOrderContent: '确认意味着已在线下处理该异常，订单将清除异常标记，恢复正常状态',
        returnOrderSuccess: '退款成功',
        onlineStore: '线上门店',
        stockOutDelivery: '缺货配送',
        stockOutDeliveryTitle: '是否要缺货配送？',
        completePack: '打包完成',
        completePackTitle: '是否确认打包完成？',
        completePackTip: '打包完成后，客户将收到去门店提货的通知',
        confirmTheDelivery: {
            loyalTNo: 'Loyal T编号',
            loaylTInputTips: '请输入长度为{{length}}字符的编号',
            paxNo: 'PAX No.',
            paxNoInputTips: '请输入长度在{{length}}位以内的数字',
            productName: '商品名称',
            productPrice: '商品单价',
            quantity: '数量',
            title: '以下商品需要维护WJA商品信息',
            csku: 'CSKU',
            trackingNumber: '快递单号',
            trackingNumberTip: '请输入快递单号',
        },
        refunds: '退货退款',
        refundsTitle: '确认订单要退货退款',
        refundsTip: '确认后将发起线上退款，请在顾客已归还商品并检查归还商品无误后操作',
        refundsConfirmTip: '当前用户会员积分不足。请确认是否执行退货退款操作。',
        confirmReceiptOfGoods: '确认商品入库',
        confirmReceiptOfGoodsTitle: '是否确认商品入库？',
        confirmReceiptOfGoodsTip: '商品拣货后顾客取消订单，请归还商品',
        locToLoc: '重发LTL',
        statusMap: {
            all: '全部',
            waitPay: '待付款',
            assembled: '待拣货',
            picked: '待打包',
            packed: '待发货',
            waitDelivery: '待发货',
            delivering: '已发货',
            completed: '已签收',
            refunding: '退款中',
            cancelled: '已取消',
            picking: '拣货中',
            paid: '已支付',
        },
        memberLabel: {
            tourist: '游客',
            member: '会员',
            staff: '员工',
        },
        orderRemarkTips: '备注内容不能为空',
        orderRemarkTipsSucc: '添加备注成功',
        orderRemarkTitle: '添加备注',
        printOrder: '打印订单',
        orderRemarkPlaceholder: '请输入备注，200字以内',
        dealAmount: '成交金额',
        orderInfoRemark: {
            remark: '备注信息',
            content: '备注内容',
            createTime: '时间',
            userName: '操作人',
        },
        confirmReceipt: {
            button: '确认收货',
            title: '是否确认订单收货？',
            content: '请确认顾客已完成收货，确认收货后订单状态将变为“已签收”',
        },
        pickedUpConfirm: {
            button: '自提收货',
            title: '是否确认订单提货？',
            content: '请确认顾客已完成提货。确认提货后订单不可取消',
        },
        progress: {
            waitPay: '提交订单',
            picked: '已拣货',
            packed: '已打包',
            cancelled: '已取消',
            orderPaid: '已付款',
            title: '订单进度',
            delivered: '已发货',
            received: '已签收',
        },
        product: {
            productList: '商品列表',
            name: '商品名称',
            salePrice: '商品单价',
            orderQuantity: '下单件数',
            quantity: '件数',
            lackQuantity: '缺货',
            pickingQuantity: '已拣',
            refundQuantity: '退款完成',
            productSumPrice: '小计',
            discountPrice: '折扣',
        },
        checkout: {
            couponAmount: '优惠券优惠',
            promotionAmount: '促销优惠',
            freightFeeDiscountAmount: '运费优惠',
            totalAmount: '商品总额',
            payDiscount: '支付优惠',
            giftCard: '代金券',
            originalOrder: '原订单小记金额',
            updateAmount: '更新后订单小记金额',
        },
        orderListItem: {
            parentOrderNumber: '父单编号',
            orderNumber: '订单编号',
            customer: '下单客户',
            amount: '实付总金额',
            dealAmount: '成交金额',
            expressDeliveryTime: '发货时间',
        },
        payMode: '支付方式',
        parentOrderNumber: '父单编号',
        rePReturnOrder: 'P重发退款请求',
        returnPOrder: 'P已完成退款',
        orderCancelToPos: '重发POS',
        info: {
            printReceipt: '打印电子收据',
        },
        confirmDelivery: '确认配送',
        expressNo: '发运单号',
        receiveMember: '收货客户',
        orderReceipt: {
            memberName: '订购人',
            memberEmail: '订单EMAIL',
            pickUpName: '收货人',
            memberMobile: '电话号码',
            pickUpMobile: '收件人电话号码',
            deliveryAddress: '运送至',
            totalPrice: '总计',
        },
        submitFreightRefund: {
            button: '人工免运费',
            title: '是否确认免除该订单运费？',
            content: '免除运费后，该订单所产生的运费，将在订单发货后进行退还；',
        },
        shipTime: '预计送货日期',
        orderStatusMsg: '请注意，该订单若取消，与之关联的以下子订单也将同步取消',
        orderCompleteMsg: '该订单存“已完成”状态的关联子订单，订单无法取消',
        viewProof: '查看支付凭证',
        paymentDocument: '支付凭证',
        concludeAmount: '成交金额：',
        paidPayAmount: '需支付金额：',
        receivedPay: '已收款',
        receivedTitle: '确认已收款',
        receivedContent: '请核实顾客是否已完成转账汇款',
        orderContent: '与之关联的以下子订单也将同步收款完成',
        couponList: {
            title: '查看优惠券',
            name: '券名称',
            type: '券类型',
            couponNum: '券码',
        },
        bulkDelivery: {
            btnText: '批量确认发货',
            modalTitle: '是否确认发货?',
            modalContent: '确认发货后订单状态将变为"已发货",批量发货订单将跳过填写发运单号步骤',
            tip: '订单处理中...',
            warnMsg: '请勾选订单',
        },
        bulkReceiving: {
            warnMsg: '请勾选订单',
            btnText: '批量确认收货',
            modalTitle: '是否确认订单收货?',
            modalContent: '请确认顾客已完成收货。确认收货后订单状态将变为"已签收"',
            tip: '订单处理中...',
        },
    },
    chinese: '简体中文',
    english: '英文',
    french: '法文',
    japanese: '日语',
    tc: '繁体中文',
    it: '意大利语',
    ko: '韩语',
    resendBl: {
        title: '重传BL',
        keywordPlaceholder: '请输入订单编号',
        orderNumber: '订单编号',
        orderId: 'ORDER ID',
        mcsType: 'MCS TYPE',
        terminalId: 'TerminalID',
        resend: '重发',
        resendRefundSales: '重发refund sales',
    },
    deposit: {
        DepositStatus: 'deposit状态',
        ToBeUsed: '待使用',
        haveBeenUsed: '已使用',
        LastUpdateTime: '最近更新时间',
        pickingListOrders: '关联订单',
        Cancelancelafterverification: '取消核销',
        ArtificialCancelAfterVerification: '人工核销',
        viewLog: '查看日志',
        DepositTheSerialNumber: 'deposit序列号',
        DepositManagement: 'deposit管理',
        keywords: '客户姓名//手机号/邮箱/SA',
        AssociatedOrder: '请输入订单号',
        ecDepositSet: 'deposit配置',
        ecDepostRules: 'deposit规则配置',
        errorMessage: '请输入{{min}}-{{max}}之间的正整数',
        rangeErrorMessage: '请输入正确的deposit 区间',
        reason: '原因',
        maximumInput: '最多输入1000个字',
        peopleCancelMessage: '请确认POS并未核销且顾客并未提货后再操作',
        cancelMessage: '操作后该deposit将被核销，请谨慎操作',
    },
    productShelf: {
        title: '商品上下架管理',
        keyword: '商品名称',
        productSource: '商品来源',
        onlineStoreName: '线上门店',
        productName: '商品名称',
        saleStatus: '销售状态',
        isShowFront: '前端是否展示',
        notShowReason: '不展示原因',
        reason: '限制上架原因',
        keywordPlaceholder: '请输入商品名称',
        brandName: '品牌',
        standardCategory: '标准分类',
        displayCategory: '展示分类',
        price: '价格',
        ISOCode: '币种',
        availableStock: '可卖数',
        safeQuantity: '安全库存',
        orderQuantity: '预占库存',
        stockQuantity: '现货库存',
        normal: '正常',
        abnormal: '异常',
        alreadyOnShelf: '已上架',
        alreadyOffShelf: '已下架',
        exportProduct: '导出商品',
        importOnShelfFile: '导入上架文件',
        importOffShelfFile: '导入下架文件',
        downloadTemplate: '下载模板',
        downloadTemplateCskuOffShelf: '根据CSKU下架',
        downloadTemplateRskuOffShelf: '根据RSKU下架',
        downloadTemplateCskuOnShelf: '根据CSKU上架',
        downloadTemplateRskuOnShelf: '根据RSKU上架',
        onShelf: '上架',
        offShelf: '下架',
        offShelfWarningTip: '商品不符合上架条件，请检查',
        offShelfWarningTips: '有商品不符合上架条件，请检查',
        batchOnShelf: '批量上架',
        batchOffShelf: '批量下架',
        viewLogs: '查看上下架日志',
        pleaseSelectProducts: '请选择商品',
        storeSelector: {
            displayStoreName: '已选 {{count}} 家门店',
            pleaseSelectStores: '请选择门店',
        },
        shelfModal: {
            onShelf: '确认上架',
            offShelf: '确认下架',
            batchOnShelf: '批量确认上架',
            batchOffShelf: '批量确认下架',
            reason: '原因',
            reasonMaxTip: '仅限{{max}}字',
        },
        logsTable: {
            onlineStoreName: '线上门店',
            productName: '商品名称',
            operateType: '操作类型',
            operatePerson: '操作人',
            operateTime: '操作时间',
            reason: '原因',
        },
    },
    productList: {
        normal: {
            title: '商品资料管理',
            view: '查看商品资料',
            add: '新增商品资料',
            edit: '编辑商品资料',
        },
        title: '商品资料管理',
        virtual: {
            title: '虚拟商品管理',
            view: '查看虚拟商品',
            edit: '编辑虚拟商品',
            add: '新增虚拟商品',
        },
        group: {
            title: '组合商品管理',
            view: '查看组合商品',
            edit: '编辑组合商品',
            add: '新增组合商品',
        },

        heroImagePath: '商品主图',
        barCode: '商品条码',
        csku: 'SKU',
        productName: '商品名称',
        productSource: '商品来源',
        productWeight: '商品重量',
        productVolume: '商品体积',
        specsType: '规格类型',
        modeType: '运输方式',
        merchantName: '合作商户',
        salesChannels: '售卖渠道',
        brandName: '品牌名称',
        brand: '商品品牌',
        saleCategory: '标准分类',
        importProductSeo: '导入商品信息',
        exportProductSep: '导出商品信息',
        productBrand: '商品品牌',
        error: '异常原因',
        modifyPeople: '修改人',
        modifyTime: '修改时间',
        mainPicture: '是否有主图',
        isDetail: '是否有详情',
        createTime: '创建时间',
        createUser: '创建人',
        hasPicture: '是否有商品图片',
        isErrorProduct: '是否异常商品',
        keywordPlaceholder: '商品名称',
        cskuPlaceHolder: '多个SKU用空格隔开',
        exportProductList: '导出商品资料',
        exportAttr: '导出分类属性',
        importProductList: '导入商品资料',
        importAttr: '导入分类属性',
        importProductPictures: '导入商品图片',
        importProductInfo: '导入商品信息',
        downloadTemplate: '下载导入模板',
        allProduct: '全部商品',
        normalProduct: '普通商品',
        yes: '是',
        no: '否',
        productInfo: '商品资料',
        type: {
            normal: '标准',
            create: '新建',
        },
        sequence: '商品排序',
    },
    productInfo: {
        imageVersion: '版本',
        mediaName: '名称',
        mediaType: '类型',
        dealStatus: '同步状态',
        createTime: '创建时间',
        productBasic: '商品基本属性',
        productEC: 'EC相关属性',
        productOther: '其他属性',
        productAll: '查看全部属性',
        productGMO: 'GMO相关属性',
        errorTip: {
            basic: '商品基本属性信息有误，请按提示修改',
            sale: '商品销售属性信息有误，请按提示修改',
            show: '商品展示属性信息有误，请按提示修改',
        },
        infoChangeFail: '商品资料修改失败',
        infoChangeSuccess: '商品资料修改成功',
        imageInfo: '商品图片和介绍',
        imageLogView: '查看图片处理日志',
        imageLog: '图片处理日志',
        lack: '缺少',
    },
    productAuth: {
        title: '商品授权管理',
        store: '门店',
        isAuth: '是否授权',
        name: '商品名称',
        barcode: '商品条码',
        organization: 'Division',
        storeName: '门店名称',
        storeCode: '门店编码',
        authType: '授权类型',
        startDate: '开始日期',
        endDate: '结束日期',
    },
    picking: {
        pickingManagement: '拣货管理',
        pickingAssistant: '拣货小助手',
        pickingAssistantDetail: '拣货单详情',
        pickingListNumber: '拣货单号',
        deliveryTag: '配送类型',
        orderId: '订单编号',
        storeNumber: '门店编号',
        storeName: '门店名称',
        keyword: '门店名称//门店编号',
        merchant: '合作商户',
        orderStatus: '订单状态',
        searchKeyword: '客户手机号',
        otherType: '其他状态',
        timeout: '拣货超时',
        waitingPick: '该拣货单还未开始拣货',
        pickInput: '输入拣货单号/订单号',
        quickPicking: '快捷拣货',
        orderNumber: '订单编号',
        posOrderNumber: 'POS订单编号:',
        pickStoreName: '提货点',
        pickStoreNameEn: '提货点 (en)',
        pickStoreNameLocal: '提货点 (local)',
        memberName: '下单客户',
        pickUpName: '提货客户',
        pickNumber: '拣货单号',
        earliestPickUpTime: '预计提货日期',
        preEarliestCanPickUpTime: '预计拣货完成时间',
        pickingListOrders: '关联订单',
        productName: '商品名称',
        saOrder: '导购订单',
        sorterName: '拣货员',
        sorterEquipment: '拣货设备',
        paper: '纸质拣货单',
        confirmPutin: '是否确认商品入库',
        confirmedPutin: '是否确认商品入库',
        selectPickingRow: '请选择拣货单',
        selectPickingResult: '请选择拣货结果',
        confirmPicking: '确认全部无缺货，打印打包单',
        batchPicking: '批量拣货完成并打印打包单',
        confirmSoldOut: '是否确认缺货配送？',
        confirmCancel: '是否确认取消订单？',
        remark: '备注：',
        mcsRemark: '给MCS传单失败，请联系顾客取消',
        lackRemark: '商品全部缺货，请联系顾客取消',
        lackProduct: '缺货商品：',
        lack: '缺货',
        pick: '已拣',
        price: '商品单价',
        gift: '赠品',
        pickAmount: '拣货后商品总金额：',
        lackDelivery: '缺货配送',
        cancelOrder: '取消订单',
        pickingComplete: '拣货完成',
        printPickingPaper: '打印拣货单',
        packageConfirmTitle: '是否确认打包完成?',
        packageConfirmContent: '打包完成后，客户将收到去门店提货的通知',
        selectOrder: '请选择订单',
        packageButton: '批量打包完成',
        exportPackageButton: '导出待打包订单',
        reSend: '重发LTL',
        packagingComplete: '打包完成',
        packageModal: {
            title: '请确认包裹数量与预计发货日期',
            batchTitle: '请确认包裹数量',
            normal: '常温货品箱数',
            freeze: '冷冻货品箱数',
            date: '预计送货日期',
        },
        printPackaging: '打印打包单',
        printPackagings: '批量打印打包单',
        batchPrintPackaging: '批量开始拣货并打印拣货单',
        starPrintPackaging: '开始拣货并打印拣货单',
        retailStoreSelectTip: '请先选择线上门店',
        status: {
            waitPicking: '待拣货',
            picking: '拣货中',
            stockOut: '缺货订单',
            picked: '待打包',
            packed: '待发货',
            cancel: '已取消',
            all: '全部',
            finishPick: '拣货完成',
            cancelled: '已取消',
        },
        deliveryFlag: '配送类型',
        parentOrderNumber: '父订单编号',
        outerOrderNumber: '订单编号',
        orderCustomer: '下单客户',
        orderCreateTime: '下单时间',
        deliveryAddress: '收货地址',
        lackShort: '缺货',
        pickingAgain: '重新拣货',
        printLabel: '打印标签',
        sn: '序号',
        productImage: '商品图片',
        brand: '商品品牌',
        size: '规格类型',
        needPickNum: '需拣数量',
        SKU: 'SKU',
        barcode: '商品条码',
        detail: {
            needPickNum: '需拣数量',
            pickedNum: '已拣数量',
            pickStatus: '拣货状态',
            noStockOut: '不缺货',
            allStockOut: '全部缺货',
            partialStockOut: '部分缺货',
            pickingInfo: '拣货信息',
            finishPickingAndPrint: '拣货完成并打印打包单',
            confirmPickingFinished: '是否确认拣货单 {{pickingListNumber}} 拣货完成？',
            exceedPartialPickingNumErrMsg: '部分商品存在多拣货',
            exceedMaxPickingNumErrMsg: '拣货数量不可大于现货库存',
            exceedMaxPickedNumErrMsg: '已拣货数量不可大于需拣货数量',
            stockChangedErrMsg: '现货库存发生变化，请重新核对拣货数量',
            productStockOutErrMsg: '此商品无库存',
            combinationErrMsg: '组合商品中，子商品未按组合比例拣货',
            combinationLocationErrMsg: '组合商品中，子商品必须从统一仓库拣货',
            locationName: 'location名称',
            availableStock: '现货库存',
            pickingNum: '拣货数量',
        },
        printReceipt: {
            packageTitle: '打包单',
            pickingTitle: '拣货单',
            printTime: '打印时间',
            locationName: '仓库名称',
            availableStock: '现货库存',
            pickingNum: '拣货数量',
            productStockOutErrMsg: '此商品无库存',
            outOfStockNum: '缺货数量',
            pickedNum: '已拣数量',
            totalQuantity: '需拣总数量（不包含称重商品）：{{totalQuantity}}件',
            exchangeGoods: '换购商品 请注意',
            giveaway: '赠品商品 请注意',
            assembly: '组合商品*{{quantity}} 请注意',
        },
        printTags: '打印箱面纸',
        batchPrintTags: '批量打印箱面纸',
        needPickTotal: '需揀總件數(不含稱重商品)',
        pickedTotal: '已揀總件數(不含稱重商品)',
        complete: '全齊',
        modifiedSuccessfully: '修改成功',
        modifyPackages: '修改包裹数量',
        confirmPackages: '请确认包裹数量',
        temperatureNums: '常温货品箱数',
        frozenNums: '冷冻货品箱数',
    },
    orderInfo: {
        title: '订单详情',
    },
    importRecord: {
        title: '日志管理',
        breadcrumbName: '导入日志管理',
        tabs: {
            productInfo: '导入商品资料',
            groupProductInfo: '导入组合商品',
            virtualProductInfo: '导入虚拟商品',
            virtualCategory: '导入虚拟分类商品',
            productShelf: '导入商品上下架',
            onlineStoreSelection: '导入线上门店选品',
            safeStock: '导入商品安全库存',
            blackList: '导入商品黑名单',
            removeBlackList: '导入移除黑名单商品',
            markGroup: '导入打标组商品',
            freebie: '导入赠品',
            brandInfo: '导入品牌资料',
            sa: '导入SA账号',
            ecouponsRule: '导入优惠券规则',
            productPrice: '导入商品价格',
            productInventory: '导入商品库存',
            overrideCode: '导入overrideCode商品',
            cashCoupon: '导入代金券',
            otherCoupon: '导入他方券',
            voucherIssuingStaff: '导入代金券发放人员',
            importMembers: '导入会员',
            importMemberPrice: '导入会员价',
            importMemberProduct: '导入会员等级黑名单商品',
            importLevelMember: '导入等级会员',
            importMemberSource: '导入会员来源',
            importMemberSourceBlackProducts: '导入会员来源黑名单商品',
            brandImportRecommendProduct: '品牌导入推荐商品',
            displayCategorysImportRecommendProduct: '展示分类导入推荐商品',
            merchantImportRecommendProduct: '合作商户导入推荐商品',
            goodsAttr: '导入商品资料分类属性',
            virtualAttr: '导入虚拟商品分类属性',
            groupGoodsAttr: '导入组合商品分类属性',
            urlMapping: 'URL Mapping导入',
            display: '展示分类导入',
            productPickupLimit: '商品提货限制',
            skuSeo: '商品SEO管理导入',
        },
        placeholders: {
            createUser: '请输入操作人',
        },
        statusNames: {
            SUCCESSFUL: '成功',
            FAIL: '失败',
            PARTIALLY_SUCCESSFUL: '部分成功',
            PROCESSING: '进行中',
        },
        createUser: '操作人',
        chooseDate: '选择日期',
        createTime: '操作时间',
        status: '操作结果',
        importResult: '导入结果',
        importResultValue: '全部（{{totalQuantity}}），失败（{{failedQuantity}}）',
        processingResult: '全部（{{totalQuantity}}）',
        exporting: '正在导出中，请耐心等候',
        ofCourseIKnown: '知道了',
        exportFailed: '导出失败',
        noFailedExcels: '无失败清单需导出',
        exportAll: '全部导出',
        exportFailures: '导出失败清单',
        sureToExportAll: '是否导出全部',
        sureToExportFailures: '是否导出失败清单',
    },
    membership: {
        title: '会员管理',
        membershipList: '会员资料管理',
        detail: '详情',
        level: '会员等级',
        id: '会员id',
        firstName: '名字',
        lastName: '姓氏',
        name: '用户名',
        email: '邮箱',
        mobile: '手机号',
        point: '积分',
        status: '账号状态',
        createTime: '注册时间',
        birthday: '生日',
        storeName: '注册门店',
        add: '创建会员',
        enable: '启用',
        disable: '冻结',
        coupon: '优惠券',
        familySize: '家庭人口',
        remark: '备注',
        lastModifiedTime: '最后更新时间',
        sourceSystem: '来源系统',
        source: '会员来源',
        checkEmail: '验证Email',
        isPush: '是否同意推送',
        viewHistoryOrder: '查看历史订单',
        importingMembers: '导入会员',
        importUpdateMembers: '导入更新会员',
        exportMembers: '导出全部会员',
        inviteCode: '邀请码',
        memberNo: 'memberNo',
        emptyMsg: {
            phone: '请填写手机号',
            email: '请填写邮箱',
            firstName: '请填写名字',
            lastName: '请填写姓氏',
            birthday: '请填写生日',
        },
        errorMsg: {
            phone: '手机号格式错误',
            email: '邮箱格式错误',
        },
        pointList: {
            orderNo: '订单号',
            point: '变动积分',
            reason: '变动原因',
            storeName: '发生门店',
            timeStr: '发生时间',
            time: '积分变动时间',
        },
        couponList: {
            couponNo: '券码编号',
            status: '优惠券状态',
            receiveTimeStr: '领取时间',
            usedTimeStr: '使用时间',
            overdueTimeStr: '过期时间',
            couponName: '券名称',
            couponTypeName: '券类型',
        },
        cashCouponList: {
            cashCouponNo: '券码编号',
            amount: '总金额',
            startTimeStr: '生效开始时间',
            endTimeStr: '生效结束时间',
            remainingAmount: '剩余金额',
            distributionPlan: '发放计划',
        },
        mobilePlaceholder: '多个手机号码用空格隔开',
        isCheck: '是否完成二次注册',
        memberType: 'Dchliving导入会员标识',
        referrer: '推荐人',
    },
    membershipLevel: {
        title: '会员等级管理',
        add: '新增会员等级',
        edit: '编辑会员等级',
        cardPicUrl: '会员卡卡面',
        memberPriceIcon: '会员价图标',
        cardPicName: '会员卡名称',
        level: '等级编码',
        name: '会员卡名称',
        picUrl: '等级图标',
        sequence: '等级排序',
        status: '状态',
        enable: '启用',
        disable: '禁用',
        expStart: '经验值起',
        expEnd: '经验值止',
        emailWhite: '邮箱白名单',
        emailWhitePlaceholder: '请输入邮箱eg :”@dch-holdings.com”,多个邮箱以英文逗号分隔',
        levelDescribe: '等级描述',
        integerTips: '请输入正整数',
        blackSkuSize: '黑名单商品',
        memberPricSize: '会员价商品',
        importProduct: '导入商品',
        importPrice: '导入商品价格',
        importMember: '导入手机号',
        type: {
            title: '会员类型',
            normal: '普通会员',
            special: '特殊会员',
        },
        memberPriceProduct: {
            title: '管理会员价',
            set: '设置价格',
            emptyError: '设置价格不能为空',
        },
        memberLevelMember: { title: '管理会员' },
        blackSkuProduct: {
            title: '管理黑名单商品',
            productName: '商品名称',
            lastedModifyUser: '更新人',
            lastedModifyTime: '更新时间',
            SKU: 'SKU',
            skuPlaceholder: '多个SKU用空格隔开',
            memberPrice: '会员价',
            mobile: '手机号码',
            mobilePlaceholder: '多个手机号用空格隔开',
            lastName: '姓氏',
            firstName: '名字',
        },
        levelError: '不能输入空格',
        exportPrice: '导出商品价格',
    },
    selectMemberDiscount: {
        title: 'select会员优惠管理',
        productSum: '商品数量',
        lastModifier: '最后修改人',
        lastModifyTime: '最后修改时间',
        importDiscount: '导入商品优惠',
        exportDiscount: '导出商品优惠',
        downloadTemplate: '下载导入模板',
    },
    sa: {
        menu: 'SA管理',
        title: 'SA账号管理',
        employeeId: 'Employee ID',
        saAccount: 'SA账号',
        posId: 'SA ID',
        password: '账号密码',
        nickName: '姓名',
        createTime: '创建时间',
        lastLoginTime: '最近登录时间',
        lastLoginStatus: '上次登录状态',
        thirdFactorStatus: 'SF状态',
        status: '登录权限',
        email: 'Email',
        keyWordsPlaceHolder: 'Email//Employee ID/SA ID/姓名',
        division: 'Division',
        onlineStore: '线上门店',
        ownDivision: '所属Division',
        ownOnlineStore: '所属线上门店',
        addUser: '新增账号',
        editUser: '编辑账号',
        newPasswordPlaceholder: '请输入新密码',
        passwordError: '密码仅支持6个字符以内的数字和字母，且不能包含特殊符号',
        changePassword: '修改密码',
        changePasswordSuccess: '密码修改成功',
        account: '账号',
        import: '批量导入',
        export: '导出',
        download: '下载导入模板',
        enableModal: {
            title: '启用用户',
            content: '是否确认启用该用户',
        },
        disableModal: {
            title: '禁用用户',
            content: '是否确认禁用该用户',
        },
        errorMsg: {
            employeeIdNotEmpty: '请输入Employee ID',
            posIdNotEmpty: '请输入POS ID',
            nickNameNotEmpty: '请输入姓名',
            passwordNotEmpty: '请输入账号密码',
            regionIdNotEmpty: '请选择所属Division',
            onlineStoreNotEmpty: '请选择所属线上门店',
            employeeId: 'Employee ID仅支持20字以内的数字和字母，字母可大小写',
            posId: 'SA ID仅支持20字以内的数字和字母，字母可大小写',
            nickName: '姓名仅支持50字以内的汉字、字母和空格，字母可大小写',
            password: '账号密码仅支持6字以内的数字和字母，字母可大小写',
            emailNotEmpty: '请输入Email',
            email: 'Email仅支持50字以内的邮箱格式',
        },
    },
    autoCancel: {
        title: '自动取消订单管理',
        link: '自动取消订单配置',
    },
    paymentDetail: {
        showDetail: '查看支付明细',
        logs: '支付明细记录',
        seq: '编号',
        type: '类型',
        name: '支付方式',
        amount: '金额',
        pspNumber: 'PSP流水号',
        status: '状态',
        time: '时间',
    },
    exportFiles: {
        breadcrumbName: '导出文件管理',
        fileName: '文件名称',
        createTime: '操作时间',
        exportStatus: '导出状态',
        statusNames: {
            SUCCESS: '成功',
            FAIL: '失败',
            PROCESSING: '进行中',
            PENDING: '待处理',
            EXPIRED: '已过期',
        },
    },
    brandEdit: {
        createBreadcrumbName: '新建品牌',
        editBreadcrumbName: '编辑品牌',
        code: '品牌编码',
        brandNameCN: '品牌中文名称',
        brandNameCNPlaceholder: '请输入品牌中文名',
        brandNameCNNoEmpty: '品牌中文名称不能为空',
        brandNamePlaceholder: '请输入品牌名称',
        brandNameCNNoLength: '品牌中文名称不能超过50个字',
        brandNameEN: '品牌英文名称',
        brandNameENPlaceholder: 'Please Enter Brand Name In English',
        brandNameENNoEmpty: '品牌英文名称不能为空',
        brandNameENNoLength: '品牌英文名称不能超过50个字',
        brandDesCNPlaceholder: '请输入品牌故事',
        brandDesCNNoLength: '品牌故事不能超过500个字',
        brandDes: '品牌故事',
        brandDesENPlaceholder: '请输入英文品牌故事',
        brandDesENNoLength: '英文品牌故事不能超过500个字',
        brandLogo: '品牌logo',
        logoTop: '顶部logo',
        mplogoBanner: 'Mobile品牌 banner',
        pclogoBanner: 'PC品牌 banner',
        logoBanner: 'Logo Banner',
        logoFeature: '特色logo',
        logoBottom: '底部logo',
        logoLimitSize: 'logo文件大小不能超过5M',
        bannerLimitSize: 'banner文件大小不能超过5M',
        leaseBrand: '是否lease brand',
        editModal: {
            modalTitle: '对应规则',
            brand: '对应品牌',
            brandRequired: '请选择对应品牌',
            category: '对应分类',
            mappingRule: '设置规则',
            inMappingRule: '设置中',
        },
        channelModal: {
            modalTitle: '对接渠道',
            merchant: '对接渠道',
            channelRequired: '请选择对接渠道',
        },
    },
    promotion: {
        title: '营促销管理',
        guideShoppingDiscount: {
            title: '导购自定义折扣管理',
            offlineCategoryName: '分类名称',
            discountNumber: '折扣上限',
            editDiscountNumber: '编辑分类折扣上限',
            categoryName: '分类名称',
            discountNumberLimit: '分类折扣上限',
        },
    },
    promotionMacau: {
        title: '促销文件管理',
        export: '导出促销信息',
        promoId: '促销ID',
        promoIdPlaceholder: '请输入促销ID',
        rskuPlaceholder: '多个RSKU用空格隔开',
        region: 'Division ID',
        store: '门店',
        promoDes: '促销描述',
        promoName: '促销名称',
        promoRule: '促销规则',
        promoQuota: '促销额度',
        qunatity: '促销数量',
        promoType: '促销类型',
        promoMode: '促销方式',
        tier: 'AP TOOL 级别',
        priority: 'AP TOOL 优先级',
        promotionStatus: '生效状态',
        startDate: '开始时间',
        endDate: '结束时间',
        promoStatus: '标签状态',
        mcsCode: 'McsCode',
        spend: '所需额度',
        exportProduct: '导出商品',
        retailStore: '线下门店',
        customerType: '用户类型',
        valid: '已生效',
        invalid: '已失效',
    },
    productSpus: {
        category: '分类',
        brands: '品牌',
        division: 'Division',
        createTime: '创建时间',
        status: '状态',
        brandId: '品牌ID',
        barndName: '品牌名称',
        title: '商品信息报告',
        productName: '产品名称',
        goodsName: '商品名称',
        itemStyle: 'VendorItemStyle',
        tab: {
            imperfect: '待完善',
            block: '已停用',
        },
    },
    resendSales: {
        title: '重发sales',
        keywordPlaceholder: '请输入订单编号 // TXN编号',
        orderNumber: '订单编号',
        salesTxn: 'SALES TXN 编号',
        refundSalesTxn: 'REFUND SALES TXN 编号',
        terminalId: 'TerminalID',
        resendSales: '重发sales',
        resendRefundSales: '重发refund sales',
    },
    exportLiability: {
        breadcrumbName: '负债表导出',
        title: '导出范围选择：',
        store: '门店',
        time: '下单时间',
        exportBtn: '导出负债表',
    },
    uploadImageVideo: {
        tip: {
            limit: '最多上传{{maxCount}}张图片或者视频',
        },
        text: {
            upload: '上传',
            img: '图片',
            video: '视频',
            uploading: '文件上传中',
        },
        error: {
            limit: '一次最多只能上传{{maxCount}}张图片或视频',
            type: '图片文件类型只能为{{maxCount}}',
            img: {
                limit: '一次最多只能上传{{maxCount}}张图片',
                size: '图片文件大小不可超过{{maxSize}}M',
            },
            video: {
                limit: '一次最多只能上传{{maxCount}}个视频',
                size: '视频文件大小不可超过{{maxSize}}M',
                duration: '视频长度不可超过{{maxDuration}}秒',
                minDuration: '视频长度不可少于{{minDuration}}秒',
                poster: '视频封面上传失败',
            },
        },
    },
    productAdd: {
        title: '新增商品资料',
        basicMessage: {
            title: '商品基本信息',
            barcode: '商品条码',
            copyProductInfo: '复制商品数据',
            barcodePlaceholder: '请输入（ABCD21031033）',
            barcodeRule: '请输入商品条码',
            productName: '商品名称',
            productNamePlaceholder: '请输入（可口可乐 500ml）',
            productBrand: '商品品牌',
            productBrandRule: '请选择商品品牌',
            selectPlaceholder: '请选择',
            standardClassify: '标准分类',
            standardClassifyWarn: '更换标准分类会导致以维护的商品分类特有属性丢失，请谨慎操作',
            productWeight: '商品重量',
            productVolume: '商品体积',
            volume: '体积',
            lengthWideHigh: '长宽高',
            specsType: '规格类型',
            common: '普通商品',
            weighing: '称重商品',
            specifications1: '售卖规格1',
            specifications2: '售卖规格2',
            specifications3: '售卖规格3',
            modeType: '运输方式',
            goodsState: '商品状态',
            merchantName: '合作商户',
            goodsTime: '商品有效期',
            timeBefore: '有效期前展示',
            stockout: '缺货售卖',
            salesChannels: '售卖渠道',
            installmentPayment: '支持分期付款',
            length: '长',
            wide: '宽',
            high: '高',
            specifications: '规格',
            specificationsPlaceholder: '请输入（瓶）',
            earlyDate: '最早送货日期',
            day: '天',
            selectProduct: '选择商品',
            childProductName: '子商品名称',
            salePrice: '商品现售价',
            saleCount: '商品可卖数',
            groupCount: '组合数量',
            cskuGroupsError: '请输入组合数量',
            okAndselectProduct: '确定并选择下一个商品',
        },
        describeMessage: {
            title: '商品描述信息',
            pic: '商品图片',
            abstract: '商品简介',
            placeholder: '请输入,多条使用空格分隔',
            label: '商品标签',
            alias: '商品别名',
            detailDes: '自定义商品详情',
            detailEnDes: '自定义商品详情',
            mobile: 'MP版',
            pc: 'PC版',
            detailShow: {
                title: '商品详情显示',
                all: '自定义商品详情与PIM商品描述均显示',
                diy: '仅显示自定义商品详情',
                pim: '仅显示PIM商品描述',
            },
            detailSort: {
                title: '商品详情排序',
                diy: '优先显示自定义商品详情',
                pim: '优先显示PIM商品描述',
            },
        },
        specialAttribute: {
            title: '分类特有属性',
            producer: '产地',
            temperature: '保存温度',
        },
        relatedProducts: { title: '相关商品' },
    },
    productEdit: {
        title: '管理商品资料编辑页',
        pic: '商品图片',
        detailShow: {
            title: '商品详情显示',
            all: '自定义商品详情与PIM商品描述均显示',
            diy: '仅显示自定义商品详情',
            pim: '仅显示PIM商品描述',
        },
        detailSort: {
            title: '商品详情排序',
            diy: '优先显示自定义商品详情',
            pim: '优先显示PIM商品描述',
        },
        detailDes: '自定义商品详情',
        detailEnDes: '自定义商品详情',
        mobile: 'MP版',
        pc: 'PC版',
    },
    distribution: {
        title: '配送管理',
    },
    merchantFreightRules: {
        title: '合作商户运费规则管理',
        merchantName: '合作商户名称',
        merchantId: '合作商户ID',
        merchantNameRequired: '合作商户不能为空',
        editMerchant: '编辑合作商户运费规则管理',
        addMerchant: '新增合作商户运费规则管理',
    },
    storeFreightRules: {
        title: '门店运费规则管理',
        onlineStoreName: '线上门店名称',
        onlineStoreCode: '线上门店编号',
        baseFreight: '基础运费',
        baseFreightNotEmpty: '请填写基础运费',
        pickUp: '自提',
        distribution: '配送',
        courier: '快递',
        minConsumeAmount: '最低消费金额',
        minConsumeAmountTips: '订单金额未满足最低消费金额，收取固定金额运费',
        AmountError: '应小于八位数',
        minConsumeAmountNotEmpty: '请填写最低消费金额',
        unsatisfiedBaseFreight: '未满足最低消费时的基础运费',
        unsatisfiedBaseFreightNotEmpty: '请填写未满足最低消费时的基础运费',
        weightStepFreigh: '重量阶梯运费',
        weightStepFreighError: '重量阶梯运费不能为空',
        startingWeight: '起始重量',
        startingWeightError: '起始重量不能为空或0',
        stepWeight: '每增加',
        stepWeightError: '每增加不能为空或0',
        stepFreight: '费用增加',
        stepFreightError: '费用增加不能为空',
        yuan: '元',
        businessTimes: '营业时间及运力',
        orderDeadlineTime: '截单时间',
        earliestDeliveryTime: '最早提货日期',
        numberError: '提货日期应输入1~999999之间的整数',
        numberError0: '最早提货日期应输入0~999999之间的整数',
        deliveryDate: '最晚提货日期',
        afterDay: '天后',
        orderDate: '下单日期',
        earliestDeliveryTimeTip: '后可提货',
        DeliveryDayTip: '天内',
        from: '至',
        day: '天',
        regionExtraFreightErr: '请选择配送规则中配送区域',
        extraFreightErr: '请填写配送规则中额外增加运费',
        startingWeightErr: '请填写重量阶梯运费中起始重量',
        stepWeightErr: '请填写重量阶梯运费中增加重量',
        stepFreightErr: '请填写重量阶梯运费中增加费用',
        distributionArea: '配送区域',
        extraFreight: '额外增加运费',
        addArea: ' + 增加配送地区',
        addConfigurable: '请先添加可配置的区域',
        select: '选择可配送区域',
        ok: '确定',
        cancel: '取消',
        amountStatsRange: '金额统计范围',
        selfFinancing: '自营商品',
        all: '订单所有商品',
        excludeDateList: '营业时间排除',
        pickUpAtHome: '自提',
    },
    report: {
        title: '报表管理',
        exportResult: '导出查询结果',
        storeId: '门店名称',
        orderTime: '订单日期',
        shipTime: '发货日期',
        deliveryTime: '送货日期',
        deliveryType: {
            title: '送货方式',
            pickUp: '自取',
            delivery: '送货上门',
            express: '快递',
        },
        orderStatus: '订单状态',
        orderNumber: '订单号码',
        contactPerson: '联络人',
        contactPersonPlaceholder: '请输入联络人姓名or联络电话',
        orderPerson: '下单人',
        orderPersonPlaceholder: '请输入下单顾客姓名or下单顾客电话',
        refundTime: '退款日期',
        refundStatus: '退款单状态',
        merchant: '合作商户',
        liquidState: '数据源',
        liquidStateType: {
            other: '通用',
            philips: 'PHILIPS',
        },
        isOfflineOrder: '售卖渠道',
        online: '线上',
        offline: '线下',
        orderShipping: {
            title: '订单发运报表',
            onlineStoreCode: '门店ID',
            name: '门店',
            outerOrderNumber: '订单号码',
            createTime: '订单日期',
            orderStatus: '订单状态',
            expressDeliveryTime: '确定发运日期',
            orderDeliveryType: '送货方式',
            parentRegionName: '区域',
            childRegionName: '地区',
            deliveryAddress: '送货地址',
            pickUpMobile: '联络电话',
            pickUpMember: '联络人',
            normalNumber: '常温货品箱数',
            coldNumber: '冷冻货品箱数',
            remark: '订单备注',
            shipTime: '送货日期',
        },
        orderSummary: {
            title: '订单汇总报表',
            onlineStoreCode: '门店ID',
            name: '门店',
            outerOrderNumber: '订单号码',
            createTime: '订单日期',
            orderStatus: '订单状态',
            expressDeliveryTime: '确定发运日期',
            orderDeliveryType: '送货方式',
            parentRegionName: '区域',
            childRegionName: '地区',
            deliveryAddress: '送货地址',
            pickUpMobile: '联络电话',
            pickUpMember: '联络人',
            orderMobile: '下单顾客电话',
            orderMember: '下单顾客名称',
            totalAmount: '订单金额',
            freightFeeAmount: '运费//服务费',
            voucherAmount: '兑换券',
            payableAmount: '信用卡',
            point: '积分',
            internalName: '支付方式',
        },
        orderDetail: {
            title: '订单明细报表',
            csku: '商品编号',
            expressDeliveryTime: '确定发运日期',
            productName: '商品名称',
            categoryName: '商品类别',
            brandName: '品牌',
            price: '单价',
            quantity: '数量',
            subtotal: '小计',
            voucherAmount: '兑换券',
            payableAmount: '信用卡',
            point: '积分',
            lackQuantity: '缺货数量',
            lackSubtotal: '缺货小计',
            finalQuantity: '最终数量',
            finalSubtotal: '最终小计',
        },
        orderDetailUnPrice: {
            title: '订单明细报表(No Price)',
        },
        refundSummary: {
            title: '退款汇总报表',
            onlineStoreCode: '门店ID',
            name: '门店',
            orderRefundNumber: '退款单号',
            outerOrderNumber: '订单号码',
            createTime: '退款日期',
            pickUpMobile: '联络电话',
            pickUpMember: '会员名称',
            orderMobile: '下单顾客电话',
            orderMember: '下单顾客名称',
            refundRemark: '退款理由',
            orderRefundStatus: '退款状态',
            refundAmount: '退款金额',
            voucherAmount: '代金券',
            payableAmount: '信用卡',
            point: '积分',
            orderRefundChannelType: '退款方式',
        },
        voucher: {
            title: '兑换券发放及使用报表',
            bonusTime: '发放日期',
            name: '发放计划名称',
            mobile: '手机号码',
            bonusPeriod: '发放周期',
            effectiveStartTime: '开始时间',
            amountPayment: '发放金额',
            num: '计划发放次数',
            memberName: '会员姓名',
            remarks: '备注',
            distributionObject: '发放对象',
            households: '家庭人数',
            issuedCoupon: '已发放兑换券',
            usedCoupon: '已使用兑换券',
            noUsedCoupon: '未使用兑换券',
        },
        productMap: {
            title: '三方映射SKU报表',
        },
    },
    coupon: {
        manage: '优惠券管理',
        title: '优惠券管理',
        promotionId: 'Promotion ID',
        couponNo: '劵码',
        phone: '手机号',
        sendOrderNo: '发券订单编号',
        useOrderNo: '核销订单编号',
        loyaltNo: '领取LoyalT No',
        useLoyaltNo: '核销LoyalT No',
        openId: 'Open ID',
        status: '领取状态',
        activeStatus: '券码状态',
        unionId: 'Unioin ID',
        sendTime: '发券日期',
        receiveTime: '领券日期',
        useTime: '核销日期',
        sendStore: '发券门店',
        receiveStore: '领券门店',
        useStore: '核销门店',
        orderNo: '订单编号',
        couponChannel: '券渠道',
        source: {
            title: '来源',
            import: '导入',
            system: '系统赠送',
        },
        obtain: {
            expired: '已过期',
            used: '已使用',
            received: '已领取',
            unReceived: '未领取',
        },
        importInfo: '优惠券',
        exportInfo: '导出优惠券',
        orderNoPlaceholder: '请输入订单编号',
        promotionIdPlaceholder: '请输入Promotion ID名称',
        codePlaceholder: '请输入券码',
        phonePlaceholder: '请输入手机号',
        loyaltNumPlaceholder: '请输入LoyalT No',
        sendStorePlaceholder: '请输入发券门店编号',
        receiveStorePlaceholder: '请输入领券门店编号',
        useStorePlaceholder: '请输入核销门店编号',
    },
    couponRule: {
        definitionId: '优惠券ID',
        importInfo: '优惠券规则',
        title: '优惠券规则管理',
        versionStatus: '版本状态',
        promotionId: 'Promotion_ID',
        status: '规则状态',
        merchant: '渠道标识',
        enable: '有效',
        disable: '失效',
        couponHeader: '券标题',
        couponDetail: '券副标题',
        couponCondition: '券规则描述',
        ecouponType: '减免类型',
        customerType: '券类型',
        perIssuance: '单人最大领取数量',
        mustBuy: '必购商品',
        maxIssuance: '最大发放数量',
        exclusionBrand: '品牌黑名单',
        loyalT: '会员限制',
        value: '优惠价值',
        spendThreshold: '门槛',
        currency: '币种',
        division: 'division',
        stores: '门店编码',
        partipateDepartment: '分类',
        partipateBrand: '品牌白名单',
        startDate: '开始时间',
        endDate: '结束时间',
        couponImage: '优惠券图片',
        marketingMessage: '优惠券详情',
        termAndConditionDetail: '条款与细则',
        exclusionCSKU: 'CSKU黑名单',
        eligibleCSKU: 'CSKU白名单',
        partipateDepartmentPlaceholder: '请输入分类ID',
        promotionIdPlaceholder: '请输入Promotion ID',
        createTime: '导入时间',
        uploadButton: '上传图片',
        storesPlaceholder: '请输入门店编码',
        couponHeaderPlaceholder: '请输入Coupon Header',
        importFile: '导入优惠券规则',
        exportFile: '导出优惠券规则',
        tag: 'Tag',
        code: '优惠码',
    },
    emailMessage: {
        title: '订单创建邮件通知管理',
        keyword: '关键词',
        edit: '编辑邮件',
        view: '查看邮件',
        keywordPlaceholder: '线上门店名称//编号',
        storeCode: '线上门店编号',
        storeName: '线上门店名称',
        emailList: '邮件地址',
        status: '状态',
        emailError: '请输入合规的邮箱地址',
        emailRepeat: '该Email已存在，请重新输入',
    },
    validation: {
        number: {
            integer: '请输入整数',
        },
    },
    productSelection: {
        title: '线上门店选品',
        keywordPlaceholder: '门店名称//编号',
        onlineStoreName: '线上门店名称',
        onlineStoreCode: '门店编号',
        count: '商品数量',
        exportInfo: '下载导入模板',
        importProduct: '导入商品',
    },
    productSuite: {
        title: '商品套装管理',
        add: '新增商品套装',
        edit: '编辑商品套装',
        name: '套装名称',
        count: '商品数量',
        modifyTime: '更新时间',
        modifyUser: '更新人',
        namePlaceholder: '请输入套装名称',
        csku: 'SKU',
        cskuPlaceholder: '请输入SKU',
        productName: '商品名称',
    },
    productSeo: {
        title: '商品SEO管理',
        edit: '编辑SEO',
        info: 'SEO详情',
        pageTitleZH: 'title（简体中文）',
        pageTitleTC: 'title（繁体中文）',
        pageTitleEN: 'title（EN）',
        megaDescriptionZH: 'Meta Description（简体中文）',
        megaDescriptionTC: 'Meta Description（繁体中文）',
        megaDescriptionEN: 'Meta Description（EN）',
        keywordsZH: 'Meta Keywords（简体中文）',
        keywordsTC: 'Meta Keywords（繁体中文）',
        keywordsEN: 'Meta Keywords（EN）',
        importSuccessTitle: '商品信息',
    },
    productSpec: {
        title: '商品款式管理',
        add: '新增款式',
        edit: '编辑款式',
        name: '款式名称',
        displayName: '展示名称',
        style: '款式值',
        addStyle: '新增款式值',
        styleError: '请输入款式值',
        styleLengthError: '商品款式不能为空',
    },
    productBarcode: '商品条码',
    productSpecsType: {
        title: '规格类型',
        normal: '普通商品',
        weigh: '称重商品',
    },
    confirmDelivery: '确认发货',
    freight: '运费/服务费',
    productMap: {
        title: '商品映射管理',
        mapSkuType: '映射SKU',
        mapSkuPlaceHolder: '多个SKU用空格隔开',
        modalNoFound: '保存失败，映射SKU不存在',
        modalRepeat: '保存失败，映射SKU已与其他商品关联',
    },
    integral: '积分',
    afterSalesManage: '售后管理',
    afterSalesOrder: {
        title: '售后单管理',
        info: '售后单详情',
        refundOrder: '退款单管理',
    },
    afterSale: {
        filters: {
            receiveAddress: '提货点',
            receiveAddressPlaceholder: '提货点名称',
            orderNumber: '订单编号',
            orderNumberPlaceholder: '多个订单号用空格隔开',
            keyWordsPlaceHolder: '手机号//客户姓名/顾客邮箱',
        },
        statusMap: {
            all: '全部',
            waitVerify: '待审核',
            approved: '审核通过',
            rejected: '审核不通过',
            completed: '已完成',
            complete: '处理完成',
            resendRefund: '重发退款请求',
            returned: '已退款',
        },
        memberType: '会员类型',
        memberTypeOption: {
            tourist: '游客',
            member: '会员',
            staff: '员工',
        },
        product: {
            salePrice: '折后金额',
            orderQuantity: '件数',
        },
        afterSaleNum: '售后单号',
        afterSaleOrderStatus: '订单状态',
        reviewer: '审核人',
        parentOrderNumber: '原始父单编号',
        orderNumber: '原始订单编号',
        customer: '申请客户',
        createTime: '申请时间',
        receiveAddress: '原始收货地址',
        afterSaleType: {
            title: '客户诉求',
            return: '退货',
            replace: '换货',
        },
        add: '创建售后单',
        selectAfterSaleNum: '选择售后商品与数量',
        createFrom: {
            orderNumber: '原始订单号',
            afterSaleType: '售后类型',
            verifyOrderNum: '验证订单号',
        },
        tips: {
            refundPayMethod: '原订单支付方式',
            refundMethod: '退款方式',
            approve: '是否确认审核通过？',
            reject: '是否确认审核不通过？',
            complete: '是否确认处理完成？',
            resend: '是否确认重新发送退款请求？',
            message: '已重发退款请求，可能需要一些时间，请您稍后刷新订单查看结果',
            return: '原路返回',
            returnVoucher: '退回为代金券',
        },
        refundOrderNum: '退款单号',
        refundItem: {
            title: '退款项目',
            paymentSerialNum: '原支付流水号',
            paymentMethods: '原支付方式',
            refundAmount: '退还金额',
            refundIntegral: '退还积分',
            refundVoucher: '退还代金券',
        },
        submitApproved: '提交并通过审核',
        noSelectTips: '未选择任何需要售后的商品',
        refundRemark: '退款原因',
        refundAmount: '退款金额',
        refundMethod: '退款方式',
        amount: '现金',
        voucherAmount: '代金券',
        pointAmount: '积分',
        freightCollected: '本单实收运费：',
        refundFreight: '退还运费',
        back: '原路退回',
        voucher: '退回为代金券',
    },
    designManager: {
        title: '装修管理',
        searchWords: {
            title: '默认搜索词管理',
            num: '搜索词数量',
            status: '状态',
            lastModifyName: '最后修改人',
            lastModifyTime: '最后修改时间',
            defaultKey: '默认搜索词{{key}}',
            setWords: '设置默认搜索词',
            required: '请输入默认搜索词',
            isExist: '默认搜索词已存在',
            setting: '设置',
        },
        hotWords: {
            title: '热词管理',
            num: '热词数量',
            status: '状态',
            lastModifyName: '最后修改人',
            lastModifyTime: '最后修改时间',
            defaultKey: '热词{{key}}',
            setWords: '设置热词',
            required: '请输入热词',
            isExist: '热词已存在',
            setting: '设置',
        },
        guessYouLikeSetting: {
            title: '猜您喜欢商品管理',
            status: '状态',
            lastModifyName: '最后修改人',
            lastModifyTime: '最后修改时间',
            setting: '设置',
            virtualCategory: '设置虚拟分类',
        },
        menuBarWords: {
            num: '菜单数量',
            menu: '菜单{{key}}',
            picture: '图片',
            title: '标题',
            url: '链接',
            titleRequire: '菜单名称不能为空',
            pictureRequire: '图片不能为空',
            setMenus: '设置菜单',
            placeholder: '请输入"https://"开头链接（选填',
            placeholderTitle: '请输入',
        },
        noticeBarWords: {
            num: '通知数量',
            menu: '通知{{key}}',
            url: '链接{{key}}',
            titleRequire: '通知名称不能为空',
            setMenus: '设置通知',
            placeholder: '请输入"https://"开头链接（选填',
            placeholderTitle: '请输入',
        },
        recommendedBrand: {
            title: '推荐品牌管理',
            setBrand: '设置品牌',
            setBrandTitle: '设置推荐品牌',
            brand: '品牌{{key}}',
            quantity: '推荐品牌数量',
            placeholder: '请输入',
            error: '请选择品牌',
        },
    },
    homeManage: {
        columns: {
            id: 'ID',
            name: '页面名称',
            onlineStoreName: '线上门店名称',
            tap: '支持触点',
            status: '状态',
            validStatus: '生效状态',
            createUser: '创建人',
            modifyUser: '最后修改人',
            createTime: '创建时间',
            lastModifyTime: '最后修改时间',
            onlineStoreIds: '线上门店',
            onlineStoreId: '线上门店ID',
        },
        filter: {
            status: '生效状态',
            keywords: '关键词',
            onlineStoreIds: '线上门店',
        },
        form: {
            name: '名称',
            nameMaxLength: '首页名称不能超过50字符',
            status: '状态',
            onlineStoreIds: '线上门店',
            touchPoint: '选择触点',
            independent: '页面组件设置',
            time: '生效时间',
        },
        statusTab: {
            1: '已生效',
            2: '未生效',
            3: '已失效',
        },
        validStatusTab: {
            1: '启用',
            2: '禁用',
        },
        touchTab: {
            1: '微信小程序',
            2: 'Web',
            3: 'App',
        },
        independentTab: {
            1: '多语言独立组件',
            2: '多语言共用组件',
        },
        independentExtra: '说明：每种语言可各自设置不同的组件类型与组件样式',
        addBtn: '新增首页',
        copy: {
            btnText: '复制',
            onlineStoreId: '线上门店',
            title: '复制页面',
            extra:
                '提示：因每个线上门店对应的线下门店可能不同，导致商品、促销等配置无法复制，请进入“装饰”页面检查组件内容',
            success: '复制成功！',
        },
        disabledEditTips: '生效状态禁止编辑',
        disabledDeleteTips: '生效状态禁止删除',
    },
    topicManage: {
        columns: {
            id: 'ID',
            name: '页面名称',
            onlineStoreName: '线上门店名称',
            tap: '支持触点',
            status: '状态',
            createUser: '创建人',
            modifyUser: '最后修改人',
            createTime: '创建时间',
            lastModifyTime: '最后修改时间',
            onlineStoreIds: '线上门店',
            onlineStoreId: '线上门店ID',
        },
        filter: {
            status: '状态',
            keywords: '关键词',
            onlineStoreIds: '线上门店',
        },
        form: {
            name: '名称',
            nameMaxLength: '专题名称不能超过50字符',
            status: '状态',
            onlineStoreIds: '线上门店',
            touchPoint: '选择触点',
            independent: '页面组件设置',
            time: '生效时间',
        },
        statusTab: {
            1: '已生效',
            2: '未生效',
            3: '已失效',
        },
        validStatusTab: {
            1: '启用',
            2: '禁用',
        },
        touchTab: {
            1: '微信小程序',
            2: 'Web',
            3: 'App',
        },
        independentTab: {
            1: '多语言独立组件',
            2: '多语言共用组件',
        },
        independentExtra: '说明：每种语言可各自设置不同的组件类型与组件样式',
        addBtn: '新增专题页',
        copy: {
            btnText: '复制',
            onlineStoreId: '线上门店',
            title: '复制页面',
            extra:
                '提示：因每个线上门店对应的线下门店可能不同，导致商品、促销等配置无法复制，请进入“装饰”页面检查组件内容',
            success: '复制成功！',
        },
        disabledEditTips: '生效状态禁止编辑',
        disabledDeleteTips: '生效状态禁止删除',
    },
    routes: {
        Home: '装修管理',
        HomeManage: '首页管理',
        HomeManageAdd: '新增首页',
        HomeManageEdit: '编辑首页',
        TopicManage: '专题活动页管理',
        TopicManageAdd: '新增专题活动页',
        TopicManageEdit: '编辑专题活动页',
        menuBarManagementView: '菜单栏管理',
        noticeBarManagement: '通知栏管理',
        recommendedBrand: '推荐品牌管理',
    },
    overrideCode: {
        title: 'OverrideCode管理',
        add: '新增Code',
        onlineStore: '适用门店',
        effectiveStartTime: '生效开始时间',
        effectiveEndTime: '生效结束时间',
        effectiveTime: '生效时间',
        type: '类型',
        status: '券状态',
        statusMap: {
            unStart: '未开始',
            finished: '已结束',
            progressing: '进行中',
        },
        typeMap: {
            custom: '自定义',
            preset: '预设',
        },
        productSelect: '选择商品',
        index: '编号',
        productName: '商品名称',
        discountPrice: '减免价格',
        emptyMsg: {
            overrideCode: 'OverrideCode不能为空',
            effectiveTime: '开始/结束时间不能为空',
            onlineStore: '请选择适用门店',
            discountPrice: '请输入减免价格',
        },
    },
    offlineManage: {
        title: '线下业务管理',
    },
    salesclerk: {
        title: '店员管理',
        add: '新增',
        modalAdd: '新增店员信息',
        userName: '用户名',
        email: '邮箱',
        userJob: '身份',
        code: '授权码',
        clerk: '店员',
        manager: '店长',
        emptyMsg: {
            userName: '请选择用户名',
            code: '请输入授权码',
        },
        errorMsg: {
            code: '请输入4位授权码，字母or数字格式',
        },
        modifyCode: '更改授权码',
    },
    thirdParty: {
        title: '三方对接管理',
    },
    thirdPartyManagement: {
        title: '三方管理',
        add: '新增信息',
        thirdPartyName: '三方名称',
        thirdPartyType: '类型',
        white: '白名单',
        permissions: '接口权限',
        whitePlaceholder: '请填写，多个以“；”隔开',
        thirdPartyNameTips: '请输入三方名称',
        sf: '三方',
        zy: '自营',
        onlineStoreIdList: '所属线上门店',
        merchantIdList: '所属合作商户',
    },
    thirdPartyAccount: {
        title: '三方账户信息',
        accountId: '账户ID',
        appKey: 'AppKey',
        publicKey: '公钥',
        privateKey: '私钥',
        thirdPartyNameTips: '请选择三方名称',
        copySuccess: '复制成功',
    },
    thirdInterfaceLogs: {
        title: '三方接口日志',
        view: '查看',
        id: '主键',
        modelName: '接口类型',
        ip: '访问IP',
        channel: '渠道',
        httpStatus: 'http状态',
        message: '结果概况',
        createTime: '调用时间',
        requestData: '请求josn',
        responseData: '返回josn',
        headerStr: 'header参数',
    },
    thirdPartyWarehouse: {
        title: '三方订单入库',
        view: '查看',
        channel: '渠道',
        parentOrderNumber: '订单编号',
        orderNumber: '原订单号',
        orderTime: '请求时间',
        goods: '商品信息',
        restock: '重新入库',
        restockTips: '确定重新入库吗',
        restockMsg: '操作成功，请稍后刷新页面查看',
        allRestock: '全部重新入库',
        allRestockTips: '确定全部重新入库吗',
        exportGoodsData: '导出商品数据',
    },
    thirdPartyDelivery: {
        title: '三方推送',
        channel: '渠道',
        parentOrderNumber: '订单编号',
        orderNumber: '原订单号',
        orderTime: '请求时间',
        responseBody: '返回参数',
        repush: '重新推送',
        repushTips: '确定重新推送吗',
        repushMsg: '操作成功，请稍后刷新页面查看',
        allRepush: '全部重新推送',
        allRepushTips: '确定全部重新推送吗',
    },
    thirdPartySync: {
        title: '三方库存同步',
        channel: '渠道',
        cskuList: 'SKU列表',
        cskuListPlaceholder: '请输入商品SKU，多个SKU用英文逗号“,”分隔',
        update: '更新库存',
        successMsg: '库存同步请求发起成功',
    },
    offlineBusiness: {
        title: '线下业务管理',
    },
    offlineOrder: {
        title: '线下订单管理',
        placeholder: {
            payAccount: '请填写',
        },
        selectGoods: {
            selectGift: '选赠品',
            exchangeGift: '去换购',
            name: '商品名称',
            sku: 'sku',
            keywords: '关键字',
            placeholder: '输入商品名称、SKU、商品条码搜索',
            commonGood: '普通商品',
            limitGood: '限购商品',
            weightGood: '称重商品',
            virtualGood: '虚拟商品',
            giftGood: '赠品',
            exchangeGood: '换购',
            addGood: '加购商品',
            selectExchangeGood: '选择换购',
            barCode: '商品条码',
            orderExchange: '订单换购',
            sellOut: '售罄',
        },
        amountConfirm: {
            freight: '运费',
            promotionAmount: '促销优惠',
            couponAmount: '优惠券优惠',
            freightFeeDiscountAmount: '运费优惠',
            pointAmount: '积分',
            voucherAmount: '代金券',
            onText: '确认提交',
            actualAmount: '需实际支付金额',
            nextStep: '下一步',
        },
        tips: {
            paymentType: '请选择支付类型',
            paymentAmount: '请填写付款支付金额',
            paymentSerialNo: '请填写数字与字母',
            paymentInfo: '请添加付款信息',
            memberPhone: '请填写会员手机号',
            purchasesNum: '请填写购买数量',
            overrideCode: '请填写OverrideCode',
            overrideMoney: '请填写Override金额',
            overInventory: '购买数量不可超过库存可买数',
            pickingStore: '请填写自取店铺',
            pickingDate: '请填写取货日期',
            pickingTime: '请填写取货时间',
            deliveryDate: '请填写送货日期',
            deliveryTime: '请填写送货时间',
            deliveryArea: '请选择送货区域',
            deliveryPosition: '请选择送货地区',
            streetAddress: '请填写详细地址',
            houseNumber: '请填写楼层/单位',
            pickUpName: '请填写收货人姓名',
            pickUpPhone: '请填写收货人联系方式',
            freight: '请填写运费',
            addPayInfoSuccess: '添加付款信息成功',
            returnAndRefund: '请填写退款金额与退货数量',
            returnLocation: '请选择退还仓库',
            lastName: '请填写姓氏',
            firstName: '请填写名称',
            pickUpPhoneLength: '收货人联系方式输入长度格式不符',
        },
        statusMap: {
            all: '全部',
            waitPay: '待付款',
            paid: '已付款',
            completed: '已签收',
            cancelled: '已取消',
            refunded: '已退款',
        },
        createOrder: '+创建订单',
        sendMessage: {
            title: '确认发送收货短信？',
            button: '短信通知',
        },
        create: {
            couponTitle: '优惠券标题',
            title: '创建订单',
            memberInfo: '会员信息',
            payTime: '付款时间',
            goodsInfo: '商品信息',
            deliveryInfo: '配送信息',
            coupon: 'Coupon',
            couponCodeBtn: '使用',
            selectMemberCoupon: '选择客户已有优惠券',
            selectMemberCashCoupon: '选择客户已有代金券',
            inputCouponCode: '请输入兑换码',
            selectText: '选用',
            selectMemberAddress: '选择顾客地址',
            discountInfo: '折扣信息',
            crateMember: '创建会员',
            memberID: '会员ID',
            discountAmount: '优惠额度',
            memberName: '会员名称',
            memberLevel: '会员等级',
            memberPrice: '会员价',
            directDiscount: '直折/直减',
            cashCoupon: '代金券',
            commonCoupon: '优惠券',
            freightCoupon: '运费券',
            submitOrder: '提交订单',
            ableSaleNum: '库存可卖数',
            stockOut: '缺货售卖',
            codeType: 'Code类型',
            noCode: '不使用Code',
            presetsCode: '预设Code',
            customCode: '自定义Code',
            overrideMoney: 'Override金额',
            codePrice: 'OVERRDE商品单价',
            removeGood: '删除商品',
            pickedGoods: '已取货',
            selectDeliveryType: '选择送货方式',
            immediatelyInStore: '店内立即取货',
            appointmentInStore: '店内取货',
            homeDelivery: '送货上门',
            pickingStore: '自取店铺',
            pickingDate: '取货日期',
            pickingTime: '取货时间',
            deliveryDate: '送货日期',
            deliveryTime: '送货时间',
            deliveryArea: '送货区域',
            deliveryPosition: '送货地区',
            streetAddress: '详细地址',
            houseNumber: '楼层/单位',
            selfPacking: '本人收货',
            otherPacking: '他人收货',
            pickUpName: '收货人姓名',
            pickUpPhone: '收货人联系方式',
            pointDiscount: '积分抵扣',
            voucherDiscount: '代金券抵扣',
            totalPrice: '订单金额小计',
            selectMember: '选择会员',
            selectGoods: '选择商品',
            selectAddress: '选择收货方式',
            selectCoupon: '选择优惠',
            inventoryDeduction: '扣减库存',
            gender: {
                '0': '女士',
                '1': '先生',
            },
            exchangePrice: '换购价',
        },
        exportOrder: '导出订单',
        exportStatements: '导出对账单',
        addPaymentInfo: '添加付款信息',
        confirmCompleted: '确认完成',
        waitPayAccount: '需支付金额',
        confirmPayAccount: '已支付金额',
        actualPayAccount: '实付总金额',
        paymentAccount: '支付金额',
        paymentSerialNo: '支付流水',
        paymentType: '支付方式',
        addPayInfo: '+添加',
        collected: '已收款',
        refundAndReturn: '退货退款',
        requiredAuthorization: '需要授权',
        confirmAuthorization: '请输入授权码完成授权',
        codeConfirmAuthorization: '该订单使用了OVERRIDECODE，请输入授权码完成授权',
        confirmCollected: '确认已收款？',
        purchasesNum: '购买数量',
        returnStock: '退还库存',
        returnLocation: '退还仓库',
        memberPhone: '会员手机号',
        tipMsg: '实付总金额与需支付金额不一致，是否继续？',
        memberPhonePlaceholder: '请输入完整的手机号、会员ID、MemberNo.查询',
        inventory: {
            insufficientInventory: '可扣减库存不足或请输入正整数',
            batchSurplusQuantity: '现货库存',
            orderQuantity: '预占库存',
            quantity: '库存扣减数',
            productName: '商品名称',
            barCode: '商品条码',
            brandLanguageName: '商品品牌',
            specsType: '规格类型',
            buyQuantity: '购买数量',
            combinationGoods: '组合商品',
            generalMerchandise: '普通商品',
            weighGoods: '称重商品',
        },
    },
    couponManage: {
        title: '卡券管理',
        common: {
            couponName: '券名称',
            provideType: {
                title: '发放类型',
                normal: '普通',
                exchange: '兑换',
            },
            addCoupon: '新增优惠券',
            startTime: '生效开始时间',
            endTime: '生效结束时间',
            discountType: {
                title: '减免类型',
                discount: '折扣',
                immediately: '立减',
            },
            applyType: {
                title: '商品范围',
                allGoods: '全部商品',
                specificGoods: '指定商品',
                specificBrand: '指定品牌',
                specificStandard: '指定标准分类',
                specificVirtual: '指定虚拟分类',
            },
            stores: '适用门店',
            couponStatus: '券状态',
            viewData: '查看数据',
            mobile: '会员手机号',
            couponCode: '券码编号',
            couponsStatus: {
                title: '优惠券状态',
                freightTitle: '运费券状态',
                waitBind: '待绑定',
                waitUse: '待使用',
                bound: '已使用',
                Expired: '已过期',
            },
            redeemCode: '兑换码',
            receiveTime: '领取时间',
            receiveStore: '领取门店',
            useTime: '使用时间',
            useStore: '使用门店',
            orderNum: '订单号',
            orderPrice: '订单金额',
            exportCoupon: '导出优惠券数据',
            exportFreightCoupon: '导出运费券数据',
            expirationDay: '领取后有效期',
            merchantId: '适用商户',
            goodLimit: '输入框内容不可超出2000字',
        },
        discountCoupon: {
            title: '优惠券管理',
            add: {
                title: '新增优惠券',
            },
            edit: {
                title: '编辑优惠券',
            },
            data: {
                title: '查看数据',
            },
        },
        freightCoupon: {
            title: '运费券管理',
            add: {
                title: '新增运费券',
            },
            edit: {
                title: '编辑运费券',
            },
            data: {
                title: '查看数据',
            },
        },
        cashCoupon: {
            title: '代金券管理',
            couponNo: '券码编号',
            mobile: '会员手机号',
            totalAmount: '总金额',
            remindAmount: '剩余金额',
            startTime: '生效开始时间',
            endTime: '生效结束时间',
            export: '导出代金券数据',
            import: '导入新代金券',
            importTip: '代金券',
        },
        thirdPartyCoupon: {
            title: '他方券管理',
            name: '券名称',
            quantity: '剩余张数',
            import: '导入券码',
            instructions: '券使用说明',
            displayName: '券展示名称',
            image: '优惠券图片',
            couponNo: '券码编号',
            status: '他方券状态',
            mobile: '会员手机号',
            grantTime: '发放时间',
            grantStoreName: '发放门店',
            imgLimitSize: '优惠券图片文件大小不能超过5M',
            statusMap: {
                waitSend: '未发放',
                sended: '已发放',
            },
            add: {
                title: '新增他方券',
            },
            edit: {
                title: '编辑他方券',
            },
            data: {
                title: '查看券码',
            },
        },
        cards: {
            base: '基本设置',
            goods: '商品设置',
            rule: '优惠券规则',
            freightRule: '运费券规则',
            use: '领取和使用规则',
            info: '优惠券图片与说明',
            freightInfo: '运费券图片与说明',
        },
        couponName: {
            title: '券名称',
            display: '券展示名称',
        },
        onlineStore: {
            title: '适用门店',
        },
        effectTime: {
            title: '生效时间',
            startTitle: '生效开始时间',
            endTitle: '生效结束时间',
        },
        paymentMode: {
            title: '指定支付方式可用',
        },
        paymentCode: {
            title: '支付code',
        },
        weekIds: {
            title: '每星期可用',
        },
        provideType: {
            title: '发放类型',
            normal: '普通',
            exchange: '兑换',
        },
        provideNum: {
            title: '发放数量',
            help: '可供顾客领取的数量，修改数量时只可增加不可减少，请谨慎设置',
        },
        storeIds: {
            title: '生效门店',
            left: '可选列表',
            right: '已选列表',
        },
        couponCode: {
            title: '优惠券code',
            placeholder: '请输入,不填写表示不可用code兑换 ',
        },
        reliefType: {
            title: '减免类型',
            discount: '折扣',
            nowReduces: '立减',
        },
        reliefNum: {
            title: '减免金额',
            placeholder: '请输入正整数，折扣类型请输入<100数字',
        },
        useType: {
            title: '使用门槛',
            none: '无使用门槛',
            amount: '订单金额满',
        },
        useNum: {
            title: '订单金额',
            error: '订单金额应大于0',
        },
        drawType: {
            title: '领取数量限制',
            none: '不限制，每用户领取数量',
            limit: '限制，每用户领取数量',
        },
        drawNum: {
            title: '领取数量限制',
        },
        orderUseType: {
            title: '每订单使用数量限制',
            none: '不限制，每订单使用数量',
            limit: '限制，每订单使用数量',
        },
        orderUseNum: {
            title: '每订单使用数量',
        },
        exclusionType: {
            title: '券互斥限制',
            none: '不限制',
            limit: '限制，与其他优惠券互斥',
        },
        description: {
            title: '使用说明',
        },
        couponPic: {
            title: '优惠券图片',
            freightTitle: '运费券图片',
        },
        applyType: {
            title: '适用商品类型',
            display: '商品范围',
            all: '全部商品',
            goods: '指定商品',
            brand: '指定品牌',
            offlineCategory: '指定标准分类',
            virtualCategory: '指定虚拟分类',
            merchant: '指定合作商户',
            goodsTitle: '商品列表',
            goodsPlaceholder: '请输入商品SKU,多个SKU用英文逗号分隔',
            brandTitle: '选择品牌',
            offlineCategoryTitle: '选择标准分类',
            virtualCategoryTitle: '选择虚拟分类',
            merchantTitle: '选择合作商户',
        },
        excludeType: {
            title: '排除商品类型',
            goods: '指定商品',
            brand: '指定品牌',
            offlineCategory: '指定标准分类',
            virtualCategory: '指定虚拟分类',
            merchant: '指定合作商户',
            goodsTitle: '商品列表',
            goodsPlaceholder: '请输入商品SKU,多个SKU用英文逗号空格分隔',
            brandTitle: '选择品牌',
            offlineCategoryTitle: '选择标准分类',
            virtualCategoryTitle: '选择虚拟分类',
            merchantTitle: '选择合作商户',
        },
        orderType: {
            title: '订单类型',
            proprietary: '自营',
        },
        couponDataStatus: {
            title: '优惠券状态',
            unbounded: '待绑定',
            unused: '待使用',
            used: '已使用',
            expired: '已过期',
        },
        status: {
            title: '券状态',
            unStart: '未开始',
            finished: '已结束',
            progressing: '进行中',
        },
        voucherManagement: {
            title: '代金券发放管理',
            editTitle: '编辑发放计划',
            addTitle: '新增发放计划',
            base: '基本设置',
            formats: '所属业态',
            rewardSetting: '奖励设置',
            helpWeek: '每周发放：每周一8：00自动发放； 券有效期为 周一0：00 至周日23：59',
            helpMonth: '每月发放：券每月1日8：00自动发放，有效期为 每月1日0：00至月底23：59',
            provisioningObjects: {
                members: '每会员',
                family: '每会员x家庭人口',
            },
            releasePeriod: {
                week: '每周',
                month: '每月',
            },
            tableCol: {
                add: '新增计划',
                name: '计划名称',
                effectTime: '生效时间',
                releasePeriod: '发放周期',
                mainDistributionStore: '主发放门店',
                status: '计划状态',
                provisioningObjects: '发放对象',
                amountPayment: '发放金额',
                numberIssues: '发放次数',
                selectPhone: '选择手机号',
            },
            groupRewards: {
                title: '奖励人群',
                index: '编号',
                memberPhone: '会员手机号',
                householdSize: '家庭人数',
            },
            errMsg: '请输入数字',
            selectStore: '请选择门店',
            mainDistributionStore: '主发放门店',
            importingMembers: '导入会员',
            exportMembership: '导出会员',
            viewRecords: '查看记录',
            viewData: {
                title: '发放记录',
                couponCode: '券码编号',
                mobile: '会员手机号',
                totalAmount: '总金额',
                balance: '剩余金额',
                effectStateTime: '生效开始时间',
                effectEndTime: '生效结束时间',
            },
        },
        couponCenter: {
            title: '领券中心',
            addTitle: '新增商品',
            editTitle: '编辑商品',
            viewTitle: '查看数据',
            base: '基本设置',
            prodcutSetting: '商品设置',
            add: '新增优惠券',
            selectCoupon: '选择优惠券',
            selectShippingCoupon: '选择运费券',
            columnsBase: {
                name: '券名称',
                reliefType: '减免类型',
                applyType: '商品范围',
                stores: '适用门店',
                discount: '折扣',
                reduced: '立减',
                allProducts: '全部商品',
                designatedProducts: '指定商品',
                designatedBrands: '指定品牌',
                designationCriteria: '指定标准分类',
                specifyVirtual: '指定虚拟分类',
                provideType: '发放类型',
                status: '券状态',
                general: '普通',
                issued: '发放',
                searchTitle: '券名称',
                searchPlaceholder: '请输入券名称',
                coupons: '优惠券',
                shippingCoupon: '运费券',
            },
            viewData: {
                name: '商品名称',
                effectTime: '生效时间',
                effectiveStartTime: '生效开始时间',
                effectiveEndTime: '生效结束时间',
                type: '获取方式',
                stores: '主领取门店',
                storeIds: '主兑换门店',
                status: '活动状态',
                sort: '商品排序',
                point: '兑换积分',
                description: '兑换说明',
                disclaimer: '免责条款',
                couponId: '选择兑换对象',
                businessType: '业态',
                collected: '待领取',
                toBeused: '待使用',
                used: '已使用',
                expired: '已过期',
            },
            viewCenterData: {
                mobile: '用户手机号',
                point: '兑换积分',
                drawTime: '兑换时间',
                couponNum: '券码',
                status: '券状态',
            },
            edit: {
                receive: '领取',
                pointRedemption: '积分兑换',
                sortPlaceholder: '数字越大越靠前',
            },
        },
        couponPackage: {
            title: '券包管理',
            addTitle: '新增券包',
            editTitle: '编辑券包',
            name: '券包名称',
            onlineStoreId: '适用门店',
            effectStartTime: '生效开始时间',
            effectEndTime: '生效结束时间',
            effectTime: '生效时间',
            code: '兑换码',
            status: '活动状态',
            couponGroup: '添加卡券',
        },
    },
    publicSentiment: {
        title: '舆情管理',
    },
    productReviews: {
        title: '商品评价管理',
    },
    orderLimitManagement: {
        title: '订单限购管理',
        addTitle: '新增限购',
        editTitle: '编辑限购',
        name: '限购名称',
        sequence: '优先级',
        specialType: '适用商品类型',
        excludeType: '排除商品类型',
        limitNum: '限购数',
        newPurchaseRestriction: '新增限购',
    },
    productPickupLimit: {
        title: '商品提货限制',
        addTitle: '新增限制',
        editTitle: '编辑限制',
        goodsName: '商品名称',
        keyword: '关键字',
        sku: 'SKU',
        merchant: '合作商户',
        merchantPickupMethods: '合作商户支持提货方式',
        pickupLimit: '商品提货限制',
        pickupConflict: '商品提货冲突',
        batchDeletion: '批量删除',
        importPickupLimit: '导入商品限制',
        downloadImportTemp: '下载导入模版',
        exportPickupLimit: '导出商品限制',
        store: '适用门店',
        goodsTitle: '适用商品',
        goodsPlaceholder: '请输入商品SKU,多个SKU用英文逗号分隔',
        pickType: '限制运输方式',
        tip: '提示信息',
    },
    salesPromotion: {
        title: '营促销管理',
        eventMarketing: {
            title: '事件营销',
            edit: '编辑活动',
            add: '新增活动',
            activityState: '活动状态',
            newActivities: '新增活动',
            register: '新会员注册',
            order: '订单满赠',
            birthday: '生日有礼',
            invitation: '邀请有礼',
            timedRelease: '定时发放',
            notBeginning: '未开始',
            progress: '进行中',
            end: '已结束',
            complete: '被邀请人注册并完成一笔订单',
            obtain: '邀请人获得',
            both: '双方获得',
            couponName: '优惠券名称',
            otherName: '他方券名称',
            issuedNumber: '发放数量',
            enterCouponName: '请输入优惠券名称',
            enterOtherName: '请输入他方券名称',
            query: '查询',
            reset: '重置',
            integral: '积分',
            pleaseEnter: '请输入',
            coupons: '优惠券',
            addCoupons: '添加优惠券',
            otherSideNote: '他方券',
            addOtherCoupons: '添加他方券',
            achieve: '完成',
            base: {
                title: '基本设置',
                name: '事件名称',
                effectiveStartTime: '生效开始时间',
                effectiveEndTime: '生效结束时间',
                typeName: '事件类型',
                type: '事件类型',
                status: '促销状态',
            },
            conditionsReward: {
                title: '奖励条件',
                orderAmount: '订单金额满',
                invitationWay: '邀请方式',
                rewardWays: '奖励方式',
                phonePlaceholder: '请输入会员手机号，多个手机号以英文逗号分隔',
                phone: '请输入手机号',
                triggerTime: '触发时间',
            },
            matchArrangement: {
                couponsListTitle: '优惠券列表',
                otherListTitle: '他方券列表',
                title: '奖励设置',
                prizeSetting: '奖品设置',
                selectReward: '请选择奖励条件',
                validNumbers: '请填写有效数字',
                orderAmount: '订单金额未填写',
            },
        },
        sub: {
            singleMultipleProduct: '单品//多品促销管理',
            add: '新增促销',
            edit: '编辑',
        },
        salesMultiPromotion: {
            filter: {
                namePromotion: '促销名称',
                typePromotion: '促销类型',
                statePromotion: '促销状态',
                applicableStores: '适用门店',
            },
            tableCol: {
                name: '促销名称',
                startTime: '生效开始时间',
                endTime: '生效结束时间',
                priority: '优先级',
                promotionType: '促销类型',
                scopeGoods: '商品范围',
                useStore: '使用门店',
                promotionState: '促销状态',
            },
        },
        promotionType: {
            title: '促销类型',
            single: '单品促销',
            multi: '多品促销',
            order: '订单促销',
        },
        baseMsg: {
            title: '基本信息',
            promotionLabel: '促销标签',
            promotionTypesRules: '促销规则类型',
            effectTime: '生效时间',
            storeIds: '适用门店',
            priorityPromotion: '促销优先级',
            errorPriorityPromotion: '8位数字以内',
            descriptionPromotion: '促销说明',
        },
        conditionsReward: {
            title: '奖励配置',
            empty: '奖励配置不能为空',
            giveGift: '赠赠品',
            tradeUpPrice: '加价换购',
            threePieces: 'X元3件',
            generalDisplay: '普通展示',
            putOnDisplay: '突出展示',
            onlyListHighlighted: '仅列表突出展示',
            stylePresentation: '展示样式',
            type: '类型',
            reward: '奖励',
            minus: '减',
            unit: '元',
            lapse: '直减',
            discount: '折扣',
            formerFold: '直折',
            serialNumber: '序号',
            scope: '范围',
            full: '满',
            letter: '件',
            upperLimit: '减免上限',
            eachFull: '每满',
            perFullSubtraction: '每满减',
            typeRewardGiveaways: '奖励类型',
            freeGiftThresholdPrice: '赠品门槛',
            changeGiftThresholdPrice: '换购门槛',
            titleGift: '赠品标题',
            chooseProduct: '选择商品',
            formExchange: '换购形式',
            changeTitle: '换购标题',
            exchangePurchasePrice: '换购价',
            selectedDefault: '默认选中',
            styleCommodityTerms: '商品条款样式',
            clauseGoods: '商品条款',
            threeTotalPrice: '3件总价',
            merchandiseGroup: '换购商品组',
            RewardType: '奖励类型',
            conditionValue: '总件数',
            prefrValue: '总价格',
            conditionValueError: '请输入2位正整数',
            prefrValueError: '请输入6位正整数,且仅能包含2位小数',
            piece: '件',
            price: '元',
            manyPieces: 'X元N件',
            exchangePrice: '请填写换购价',
        },
        formExchange: {
            radio: '单选',
            multiSelect: '多选',
        },
        styleCommodityTerms: {
            noPrompt: '无提示',
            generalTips: '普通提示',
            popUpMessage: '弹出提示',
        },
        merchandiseGroup: {
            sameMainGroup: '同主组',
            additionalGrouping: '额外分组',
        },
        conditionQualification: {
            title: '限定条件',
            applicableCommodityType: '适用商品类型',
            applicableBrand: '适用品牌',
            excludingTypeGoods: '排除商品类型',
            listGoods: '商品列表',
            typeCondition: '条件类型',
            typeReward: '奖励类型',
            memberLevelIds: '适用会员等级',
        },
        typeCondition: {
            no: '无条件',
            count: '件数满',
            money: '金额满',
        },
        rewardType: {
            discount: '直折%',
            money: '直减$',
            chooseProduct: '加价选购',
            gift: '赠赠品',
            stepMoney: '满减/阶梯满减',
            stepDiscount: '满折/阶梯满折',
            satisfyMoney: '每满减',
            manyPieces: 'X元N件',
        },
        complete: {
            title: '完成',
        },
        promotionLabel: '促销标签',
        newPromotions: '新增促销',
        deletionGoods: '删除商品',
        newGoodsAdded: '新增商品',
        previousStep: '上一步',
        nextStep: '下一步',
        stairFullSubtraction: '满减/阶梯满减',
        fullFold: '满折/阶梯满折',
        newItemAdded: '新增一条',
        submit: '确认提交',
        allComplete: '全齐',
    },
    publicOpinion: {
        title: '舆情管理',
    },
    commodityEvaluation: {
        title: '商品评价管理',
        statusMap: {
            waitVerify: '未审核',
            approved: '审核通过',
            rejected: '审核不通过',
        },
        batchAudit: {
            approve: '批量审核通过',
            reject: '批量审核不通过',
        },
        productName: '商品名称',
        contentReview: '评论内容',
        score: '分值',
        reviewer: '评论人',
        associatedOrder: '关联订单',
        productReview: '评论商品',
        associatedOrderNum: '关联订单号',
        evaluationExamine: '商品评价审核',
        evaluationView: '查看',
        resolve: '通过',
        examineResolve: '审核通过',
        reject: '不通过',
        evaluationScore: '评分分值',
        evaluationTime: '评价时间',
        evaluationPicture: '评价图片',
        placeholder: {
            evaluatorNumber: '请输入手机号',
            sku: '请输入SKU',
        },
    },
    philipsOrder: {
        title: '飞利浦订单管理',
        subTitle: '订单管理',
    },
    pickingPhilips: {
        title: '飞利浦拣货管理',
        subTitle: '飞利浦拣货小助手',
    },
    memberExamine: {
        title: '特殊等级审核',
        mobile: '手机号码',
        lastName: '姓氏',
        firstName: '名',
        email: '邮箱',
        applyTime: '申请时间',
        name: '用户名',
        levelName: '等级变更',
        status: {
            pending: '待审核',
            approved: '审核通过',
            rejected: '审核未通过',
        },
        selectPlaceholder: '请选择等级',
        reviewedTime: '审核时间',
        reviewer: '审核人',
        selectLevelName: '请选变更等级',
    },
    memberSource: {
        title: '会员来源管理',
        index: '序号',
        source: '会员来源',
        remark: '备注',
        dchlivingLogo: 'DCHLiving logo',
        foodmartLogo: 'Foodmart logo',
        sourceIcon: '会员来源图标',
        add: '新增会员来源',
        edit: '编辑会员来源',
        importUpdate: '导入会员来源',
        export: '导出会员来源',
        sourceValidatorTips: '仅支持大小写字母、数字，字符限制60',
    },
    notification: {
        title: '通知管理',
    },
    siteMessages: {
        title: '站内信管理',
        add: '新增通知',
        edit: '编辑通知',
        name: '通知名称',
        sendTime: '发送时间',
        subject: '发送标题',
        content: '发送内容',
        isOpen: '链接地址',
        urlSubject: '链接标题',
        targetUrl: '跳转地址',
        sendType: '发送人群',
        sendTypeMap: {
            all: '全部会员',
            part: '指定会员',
        },
        phoneNumbers: '请输入手机号',
        phoneNumbersPlaceholder: '请输入手机号，多个手机号以英文逗号“,”分隔',
        status: '计划状态',
    },
    popupAds: {
        title: '弹窗广告管理',
        add: '新增广告',
        edit: '编辑广告',
        activityName: '活动名称',
        displayTime: '展示时间',
        displayStartTime: '展示开始',
        displayEndTime: '展示结束',
        displayStore: '展示门店',
        displayLocation: '展示位置',
        displayLocationMap: {
            home: '首页',
            brand: '指定品牌',
            category: '指定分类',
            merchant: '指定商户',
        },
        selectBrand: '选择品牌',
        selectCategory: '选择展示分类',
        selectMerchant: '选择合作商户',
        sendFrequency: '触发频率',
        sendFrequencyMap: {
            0: '每天不限次数',
            1: '每人每天1次',
            2: '每人每天2次',
            3: '每人每天3次',
        },
        priority: '优先级',
        popupAdPc: '弹窗广告（PC）',
        popupAdMob: '弹窗广告（Mob）',
        redirectUrl: '跳转地址',
        lastModifyUser: '最后修改人',
        lastModifyTime: '最后修改时间',
        activityStatus: '活动状态',
        activityStatusMap: {
            notStarted: '未开始',
            inProgress: '进行中',
            completed: '已结束',
        },
        onlineStoreId: '适用门店',
    },
    systemManagement: {
        title: '系统管理',
        urlMapping: {
            title: 'URL Mapping管理',
            import: '导入',
            export: '导出',
            view: '查看',
            channelType: '渠道名称',
            oldUrl: 'Old URL',
            newUrl: 'New URL',
        },
        paymentMode: {
            title: '支付方式管理',
            add: '新增支付方式',
            addText: '新增支付方式',
            edit: '编辑支付方式',
            name: '支付方式名称',
            internalName: '内部识别名称',
            channel: '渠道名称',
            channelName: '渠道',
            sequence: '排序',
            status: '状态',
            lastModifyUser: '修改人',
            lastModifyTime: '最后修改时间',
            code: '支付参数',
            descTime: '描述生效时间',
            allow: '同時支持分期及一次性付款',
            notAllow: '不支持',
            onlyAllow: '仅支持',
            installmentFlag: '是否支持分期付款',
            offlineFlag: '是否支持线下支付',
            logo: 'Logo',
            desc: '描述',
        },
    },
    orderManagement: {
        pageTitle: '自取訂單收貨系統',
        placeholder: '請輸入訂單號碼',
        queryOrder: '查詢訂單',
        orderNumber: '訂單號碼',
        errorMsg: '請輸入最少6位訂單號碼',
        emptyMsg: '未找到符合條件的訂單號，請核對訂單號碼與訂單狀態',
        codeErrorMsg: '發送驗證碼失敗',
        successMsg: '收貨成功',
        codeInput: '請輸入驗證碼',
        resend: '重新發送',
        cancel: '取消',
        confirmReceipt: '確認收貨',
        orderTitle: '查詢到的訂單',
        close: '關閉',
        merchantName: '合作商戶名稱',
        orderCustomer: '下單客戶',
        orderTime: '下單時間',
        consigneeMobile: '已向收貨人手機{{mobile}}發送驗證碼',
    },
};
